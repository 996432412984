import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../common/Button';
import ButtonBox from '../../common/ButtonBox';
import ContentBox from '../../common/ContentBox';
import FeedbackAndReceipt from '../../common/FeedbackAndReceipt';
import Input from '../../common/Input';
import InputValidationMsg from '../../common/InputValidationMsg';
import PrimaryButton from '../../common/PrimaryButton';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';

import { useRecoilState, useRecoilValue } from 'recoil';
import { AVAILABLE_WATER_VOLUMES } from '../../../constants';
import { LIMITS } from '../../../screensSettings';
import { orderState, qrState, vmState } from '../../../state/recoilState';
import { BasicScreenProps, ScreenWithTimerProps } from '../../../types';
import { setOrderAmount } from '../../../utils/payments';

import useTimer from '../../../hooks/useTimer';
import { cl, screenLog } from '../../../utils/general';
import { trackPageView } from '../../../utils/tracking';
import PaymentButtons from '../../common/PaymentButtons/PaymentButtons';
import style from './WaterScreen.module.css';

const WaterCard = (props: any) => {
    const { onClick, children, isSelected } = props;
    return (
        <div
            className={
                style.waterCard + ` waterCard ${isSelected && 'selectedCard'}`
            }
            onClick={onClick}
        >
            {children}
        </div>
    );
};

const WaterCardsBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -10px 15px;
    padding: 0 10px;
`;

const PriceBox = styled.div`
    font-weight: 600;
    font-size: 33px;
    line-height: 40px;
    text-align: center;
    color: #1b3f92;
`;

type WaterScreenProps = ScreenWithTimerProps;

const WaterScreen = ({
    showFeedback = false,
    showReceipt,
    onGetReceipt,
    onFeedback,
    onCancel,
    onTimerTimeoutHandler,
}: WaterScreenProps) => {
    const { t } = useTranslation();
    const timerHook = useTimer();

    const [volume, setVolume] = useState<number | ''>('');
    const [amount, setAmount] = useState<number>(0);
    const [error, setError] = useState<string>();
    const [showPaymentButton, setShowPaymentButton] = useState<boolean>(false);
    const [customVolume, setCustomVolume] = useState<number | ''>();
    const [order, setOrder] = useRecoilState(orderState);
    const vm = useRecoilValue(vmState);
    const litrePrice = vm.planogram ? vm.planogram[0].price : 0;

    const qr = useRecoilValue<string>(qrState);
    const [amountSend, setAmountSend] = useState<boolean>(false);

    useEffect(() => {
        trackPageView('water screen');
        screenLog('Water Screen');
        timerHook.start();

        return () => {
            // cl('unmount WaterScreen');
            timerHook.reset();
        };
    }, []);

    useEffect(() => {
        if (timerHook.time <= 0) {
            // cl(' timer end - redirect to home', timerHook.time);
            timerHook.reset();
            onTimerTimeoutHandler && onTimerTimeoutHandler();
        }
    }, [timerHook.time]);

    useEffect(() => {
        const orderAmount = Math.round(Number(volume) * 100 * litrePrice);
        setAmount(orderAmount);
        setOrderAmount(order.id as string, orderAmount, setAmountSend);
        setOrder({
            ...order,
            amount: orderAmount,
        });
        cl('amount', orderAmount);

        setShowPaymentButton(
            volume != '' &&
                volume >= LIMITS.WATER.MIN &&
                volume <= LIMITS.WATER.MAX
        );
        // cl(
        //   'showPaymentButton',
        //   showPaymentButton,
        //   volume != '' && volume >= LIMITS.WATER.MIN && volume <= LIMITS.WATER.MAX,
        //   volume,
        //   LIMITS.WATER.MIN,
        //   LIMITS.WATER.MAX
        // );
    }, [volume]);

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Select water')}</ScreenTitle>
            <ContentBox>
                <WaterCardsBox>
                    {AVAILABLE_WATER_VOLUMES.map((number) => (
                        <WaterCard
                            key={number}
                            onClick={() => {
                                setVolume(number);
                                setCustomVolume(number);
                                cl('volume', number);
                            }}
                            isSelected={volume === number}
                        >
                            <span>
                                {number} {t('Litre symbol').toUpperCase()}
                            </span>
                        </WaterCard>
                    ))}
                </WaterCardsBox>
                <Input
                    type='number'
                    placeholder={t<string>('Select water')}
                    value={customVolume && customVolume > 0 ? customVolume : ''}
                    // min={LIMITS.WATER.MIN}
                    max={LIMITS.WATER.MAX}
                    onChange={(event) => {
                        setCustomVolume(Number(event.target.value));
                        setVolume(Number(event.target.value));
                    }}
                />
                {vm?.campaign?.message && (
                    <div className='promotext'>
                        <span>{vm?.campaign?.message}</span>
                    </div>
                )}
                <PriceBox>
                    <span id='price'>{(amount / 100).toFixed(2)}</span>{' '}
                    {t(`Currency.${vm?.currency_code}`)}
                </PriceBox>

                {showPaymentButton && amountSend ? (
                    <ButtonBox>
                        <PaymentButtons />
                        <Button onClick={onCancel}>{t('Cancel')}</Button>
                    </ButtonBox>
                ) : (
                    <FeedbackAndReceipt
                        showReceipt={showReceipt}
                        showFeedback={showFeedback}
                        onCancelClick={onCancel}
                        onReceiptClick={onGetReceipt}
                        onFeedbackClick={onFeedback}
                    />
                )}

                {/* {showPaymentButton && <PaymentButtons />}
                <Button onClick={onCancel}>{t('Cancel')}</Button> */}
            </ContentBox>
        </ScreenWrapper>
    );
};

export default WaterScreen;
