import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { ERROR_MESSAGES } from '../../../constants';
import { orderState } from '../../../state/recoilState';
import { BasicScreenProps } from '../../../types';
import { screenLog } from '../../../utils/general';
import { trackPageView } from '../../../utils/tracking';
import { Button } from '../../common/Button';
import ContentBox from '../../common/ContentBox';
import FeedbackCustom from '../../common/FeedbackButtonLogic';
import PrimaryButton from '../../common/PrimaryButton';
import SVGObject from '../../common/SVGObject';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';

interface FailureScreenProps extends BasicScreenProps {
    onRepeatPurchase: () => void;
    title?: string;
}

const PriceExplanation = styled.div`
    margin: 1vh 0 0 0;
    font-size: min(4.4vw, 26px);
    font-weight: medium;
    color: rgb(252 0 0);
    text-align: center;
    line-height: normal;
`;

const FailureScreen = (props: FailureScreenProps) => {
    const { title, showFeedback, onFeedback, onRepeatPurchase } = props;
    const { t } = useTranslation();
    const order = useRecoilValue(orderState);
    const [paymentError, setPaymentError] = useState('');
    const parsedExtra = JSON.parse(order.extra ?? 'null');
    const codeError = parsedExtra?.commitResult?.result?.errorCode || null;

    const handlePaymentError = (codeError: string) => {
        setPaymentError(ERROR_MESSAGES[codeError] || 'Payment Error');
    };

    useEffect(() => {
        trackPageView(title || 'failure', { orderId: order.id, ...order });
        screenLog('Failure');
        handlePaymentError(codeError);
    }, []);

    return (
        <ScreenWrapper>
            <ScreenTitle>{t(title || 'Failed sale')}</ScreenTitle>
            <ContentBox>
                {codeError && (
                    <PriceExplanation className='error'>
                        {t(paymentError)}
                    </PriceExplanation>
                )}
                <SVGObject
                    src='/cross.html'
                    style={{
                        // marginTop: "-2vmax",
                        height: '230px',
                        width: 'min(100vw, 500px)',
                    }}
                />

                <FeedbackCustom
                    showFeedback={showFeedback}
                    onFeedbackClick={onFeedback}
                />

                <Button onClick={onRepeatPurchase}>
                    {t('Repeat_purchase')}
                </Button>
            </ContentBox>
        </ScreenWrapper>
    );
};

export default FailureScreen;
