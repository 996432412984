import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import useTimer from '../../../hooks/useTimer';
import { LIMITS } from '../../../screensSettings';
import { orderState, vmState } from '../../../state/recoilState';
import { BasicScreenProps, ScreenWithTimerProps } from '../../../types';
import { cl, screenLog } from '../../../utils/general';
import { setOrderAmount } from '../../../utils/payments';
import { trackPageView } from '../../../utils/tracking';
import { Button } from '../../common/Button';
import ButtonBox from '../../common/ButtonBox';
import ContentBox from '../../common/ContentBox';
import DiscountMessage from '../../common/DiscountMessage';
import ErrorText from '../../common/ErrorText';
import FeedbackAndReceipt from '../../common/FeedbackAndReceipt';
import Input from '../../common/Input';
import InputWrapper from '../../common/InputWrapper';
import PaymentButtons from '../../common/PaymentButtons/PaymentButtons';
import PriceBox from '../../common/PriceBox';
import PrimaryButton from '../../common/PrimaryButton';
import PromoText from '../../common/PromoText';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';

const NoteMessage = styled.div`
    color: rgb(92 129 237);
    text-align: center;
`;

type SnackScreenProps = ScreenWithTimerProps;

const SnackScreen = (props: SnackScreenProps) => {
    const {
        showFeedback,
        onFeedback,
        onCancel,
        onTimerTimeoutHandler,
        showReceipt,
        onGetReceipt,
    } = props;
    const { t } = useTranslation();
    const timerHook = useTimer();
    const navigate = useNavigate();

    const [order, setOrder] = useRecoilState(orderState);
    const vm = useRecoilValue(vmState);
    const [amount, setAmount] = useState<number>(0);
    const [showPromoText] = useState<boolean>(
        Boolean(vm?.campaign?.message && vm?.campaign?.message.length > 0)
    );
    const [showPaymentButton, setShowPaymentButton] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [amountSend, setAmountSend] = useState<boolean>(false);

    useEffect(() => {
        trackPageView('snack screen', { orderId: order.id, ...order });
        screenLog('Snack Screen');
        timerHook.start();

        return () => {
            cl('unmount SnackScreen');
            timerHook.reset();
            // onCancel && onCancel();
        };
    }, []);

    useEffect(() => {
        if (timerHook.time <= 0) {
            cl(' timer end - redirect to home', timerHook.time);
            timerHook.reset();
            onTimerTimeoutHandler && onTimerTimeoutHandler();
        }
    }, [timerHook.time]);

    useEffect(() => {
        const discount = vm?.campaign?.discount_percentage
            ? 1 - vm?.campaign?.discount_percentage / 100
            : 1;
        const realAmount = amount * 100 * discount;
        setShowPaymentButton(
            amount >= LIMITS.SNACK.MIN && amount <= LIMITS.SNACK.MAX
        );
        setOrderAmount(order.id as string, realAmount, setAmountSend);
        setOrder({
            ...order,
            amount: realAmount,
        });
        cl('amount', realAmount);
    }, [amount]);

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Enter exact amount')}</ScreenTitle>
            <ContentBox>
                <NoteMessage>{t('Enter exact description')}</NoteMessage>

                <InputWrapper>
                    <Input
                        type='number'
                        inputMode='decimal'
                        min={LIMITS.SNACK.MIN}
                        max={LIMITS.SNACK.MAX}
                        onChange={(event) => {
                            const input = event.target.value.replace(',', '.');
                            const maxLimit = Math.min(
                                vm.max_credit_app,
                                LIMITS.PAYMENT_CT10.MAX
                            );
                            let enteredAmount = Number(input);

                            if (enteredAmount > maxLimit) {
                                enteredAmount = maxLimit;

                                setError(
                                    `${t('The entered amount is too big.')} ${t(
                                        'Max amount is'
                                    )} ${vm.max_credit_app} ${t(
                                        `Currency.${vm?.currency_code}`
                                    )}`
                                );
                                setShowPaymentButton(false);
                            } else {
                                setError('');
                                // setShowPaymentButton(true);
                            }

                            setAmount(Number(event.target.value));
                        }}
                        step='1'
                        placeholder={t<string>('Amount')}
                        value={amount > 0 ? amount : ''}
                    />
                    <ErrorText className='error'>{error}</ErrorText>
                </InputWrapper>
                <div className='pay_text'>{t('Order payment')}</div>
                {showPromoText && (
                    <PromoText>{vm?.campaign?.message}</PromoText>
                )}
                <div>
                    <PriceBox>
                        <span id='price'>
                            {(order?.amount / 100).toFixed(2)}
                        </span>{' '}
                        {t(`Currency.${vm?.currency_code}`)}
                    </PriceBox>

                    {/* {Boolean(vm.campaign?.discount_percentage) && (
            <DiscountMessage>
              {t('Your discount is ')}
              {vm.campaign?.discount_percentage}%
            </DiscountMessage>
          )} */}
                </div>
                {showPaymentButton && amountSend ? (
                    <ButtonBox>
                        <PaymentButtons />
                        <Button onClick={onCancel}>{t('Cancel')}</Button>
                    </ButtonBox>
                ) : (
                    <FeedbackAndReceipt
                        showReceipt={showReceipt}
                        showFeedback={showFeedback}
                        onCancelClick={onCancel}
                        onReceiptClick={onGetReceipt}
                        onFeedbackClick={onFeedback}
                    />
                )}
                {/* {showPaymentButton && <PaymentButtons />}
                <Button onClick={onCancel}>{t('Cancel')}</Button> */}
            </ContentBox>
        </ScreenWrapper>
    );
};

export default SnackScreen;
