import { SvgIcon } from '@mui/material';

export function CookieIcon() {
  return (
    <SvgIcon sx={{marginRight:'-5px', marginLeft:'-10px'}} key="GoodColor">
      <svg
        style={{fontSize:'40px'}}
        width="46"
        height="35"
        viewBox="0 0 46 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_1779_472)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.7027 11.7142C21.0206 11.2478 21.2065 10.6842 21.2065 10.0771C21.2065 9.80225 21.1684 9.53629 21.0972 9.28423C21.5532 9.20692 22.0219 9.16666 22.5 9.16666C27.1024 9.16666 30.8333 12.8976 30.8333 17.5C30.8333 22.1024 27.1024 25.8333 22.5 25.8333C18.0564 25.8333 14.4251 22.3554 14.1799 17.9731C14.3098 17.9908 14.4424 18 14.5771 18C15.3274 18 16.0113 17.7161 16.5274 17.2499C16.7933 17.4197 17.0916 17.5149 17.4067 17.5149C18.4645 17.5149 19.3325 16.4423 19.4205 15.0761C20.4696 14.734 21.2065 13.9277 21.2065 12.9876C21.2065 12.5154 21.0206 12.077 20.7027 11.7142Z"
            fill="#1B3F92"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.5 10.1667C21.5 10.7924 21.3084 11.3734 20.9808 11.8542C21.3084 12.2281 21.5 12.68 21.5 13.1667C21.5 14.1357 20.7404 14.9668 19.6591 15.3195C19.5684 16.7277 18.6737 17.8333 17.5833 17.8333C17.2585 17.8333 16.951 17.7352 16.677 17.5601C16.1551 18.0316 15.4666 18.3222 14.7104 18.333C15.1264 22.2676 18.4552 25.3333 22.5 25.3333C26.8262 25.3333 30.3333 21.8262 30.3333 17.5C30.3333 13.1738 26.8262 9.66666 22.5 9.66666C22.1505 9.66666 21.8064 9.68954 21.469 9.7339C21.4894 9.8752 21.5 10.0197 21.5 10.1667ZM24.5255 14.9303C24.9077 15.1866 25.4877 14.9916 25.821 14.4947C26.1542 13.9977 26.1144 13.3871 25.7322 13.1308C25.3499 12.8745 24.7699 13.0695 24.4367 13.5664C24.1035 14.0634 24.1432 14.674 24.5255 14.9303ZM22.9205 21.8021C23.3191 22.0322 23.8672 21.8289 24.1449 21.3481C24.4225 20.8672 24.3244 20.2909 23.9259 20.0607C23.5273 19.8306 22.9791 20.0339 22.7015 20.5147C22.4239 20.9956 22.5219 21.572 22.9205 21.8021ZM22.1758 18.0985C21.9967 18.6132 21.5831 18.937 21.252 18.8218C20.9209 18.7066 20.7976 18.196 20.9767 17.6813C21.1558 17.1667 21.5694 16.8428 21.9005 16.9581C22.2316 17.0733 22.3548 17.5839 22.1758 18.0985ZM26.7831 18.7094C27.4402 18.7987 28.0295 18.455 28.0992 17.9416C28.1689 17.4283 27.6928 16.9398 27.0356 16.8505C26.3785 16.7613 25.7892 17.105 25.7195 17.6183C25.6497 18.1317 26.1259 18.6202 26.7831 18.7094ZM19.8557 21.0398C19.7769 21.6197 19.1804 22.0173 18.5232 21.9281C17.8661 21.8388 17.3972 21.2964 17.476 20.7165C17.5548 20.1367 18.1513 19.739 18.8085 19.8283C19.4656 19.9176 19.9345 20.46 19.8557 21.0398Z"
            fill="#DAE3F7"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1779_472"
            x="-15"
            y="-5"
            width="65"
            height="65"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-5" dy="10" />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1779_472"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1779_472"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
}