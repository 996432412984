import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { orderState, vmState } from '../../../state/recoilState';
// import useApplePayment from '../../../hooks/useApplePayment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cl } from '../../../utils/general';
import {
    postGooglePayment,
    validateAppleMerchant,
} from '../../../utils/payments';
import ApplePayButton from './ApplePayButton/ApplePayButton';
import GooglePayBtn from './GooglePayBtn';
import TBCPayButton from './TBCPayButton/TBCPayButton';

import * as Sentry from '@sentry/react';
import { PAYMENT_PROVIDER } from '../../../constants';
import { trackClick, trackEvent } from '../../../utils/tracking';

import style from './PaymentButtons.module.css';

// Logic
// 1. check available payment methods
// 2. show payment buttons
// 3. on payment button click

const PaymentButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1vh 0 0 0;
`;

const PaymentButtons = () => {
    const order = useRecoilValue(orderState);
    const vm = useRecoilValue(vmState);
    const navigate = useNavigate();
    cl('  🤑 [payment] \tvm:', vm);
    const isTBC = vm?.payment_provider_id === PAYMENT_PROVIDER.TWO;
    // const isApplePaySupported = useApplePayment(vm.payment_provider_id);
    const isApplePaySupported = Boolean(window.ApplePaySession);
    const { t } = useTranslation();

    cl('kmg', 'payment provider id', vm.payment_provider_id);
    cl('kmg', 'isApplePaySupported', isApplePaySupported);
    const [isGooglePaySupported, setIsGooglePaySupported] = useState<boolean>(
        !isApplePaySupported
    );
    cl('kmg', 'isGooglePaySupported', isGooglePaySupported);
    const [isTbcPaySupported] = useState<boolean>(isTBC);
    cl('kmg', 'isTbcPaySupported', isTbcPaySupported);

    // if (isApplePaySupported) {
    //     const merchantId = import.meta.env.VITE_APPLE_MERCHANT_ID;
    //     window.ApplePaySession?.canMakePaymentsWithActiveCard(merchantId).then(
    //         (canMakePayments: any) => {
    //             cl('kmg', 'canMakePayments', canMakePayments);
    //             // setShowPaymentButton(canMakePayments);
    //         }
    //     );
    // }

    const onError = (err: any) => {
        cl('kmg', 'Payment Error', err);
        Sentry.captureException(err);
        trackEvent('Payment error', {
            error: err,
            orderId: order.id,
            ...order,
        });
        navigate(`/${order.id}/failed`, { replace: true });
    };

    const onTBCPaymentFailed = () => {
        cl('kmg', 'Payment Failed');
        trackEvent('Payment failed - TBC', { orderId: order.id, ...order });
        navigate(`/${order.id}/failed`, { replace: true });
    };

    const onTBCCheckoutSuccess = (result: any) => {
        cl('kmg', 'onTBCCheckoutSuccess', result);
        const path = `/${order.id}/failed`;
        if (result.uri) {
            trackEvent('Payment success - TBC', {
                result,
                orderId: order.id,
                ...order,
            });
            window.location.href = result.uri;
        } else {
            trackEvent('Payment failed - TBC - no url', {
                result,
                orderId: order.id,
                ...order,
            });
            navigate(path, { replace: true });
        }
    };

    const onGoogleCheckoutSuccess = (paymentResponse: any) => {
        cl('kmg', 'CT10 - onPaymentCompleted', paymentResponse);
        // setTimeout just to avoid component unmounting before payment request
        setTimeout(() => {
            navigate(`/${order.id}/wait`, { replace: true });
        }, 100);
        postGooglePayment(order?.id as string, paymentResponse, () => {
            cl('kmg', 'GPay', paymentResponse);
            trackEvent('Google payment success', {
                orderId: order.id,
                ...order,
            });

            // if CT10 - wait
            // navigate(`/${order.id}/wait`, { replace: true });
        });
    };

    const onApplePayCheckoutSuccess = (paymentResponse: any) => {
        trackEvent('Apple payment success', { orderId: order.id, ...order });
        cl('kmg', 'onPaymentCompleted', paymentResponse);
        navigate(`/${order.id}/wait`, { replace: true });
    };

    function onRequestApplePay() {
        trackClick('Apple pay button', { orderId: order.id, ...order });

        const paymentRequest = {
            currencyCode: vm?.currency_code,
            countryCode: 'UA',
            total: {
                label: t('Product'),
                amount: order?.amount / 100,
            },
            merchantCapabilities: ['supports3DS'],
            supportedNetworks: ['masterCard', 'visa'],
        };

        if (!window.ApplePaySession?.canMakePayments()) {
            trackEvent('Apple payment failed - Safari - canMakePayments', {
                orderId: order.id,
                ...order,
            });
            return;
        }

        // @ts-ignore-next-line
        const session = new window.ApplePaySession(3, paymentRequest);

        session.onvalidatemerchant = (event: any) => {
            trackEvent('Apple merchant validation', {
                orderId: order.id,
                ...order,
            });
            validateAppleMerchant(event.validationURL).then(
                (merchantSession) => {
                    trackEvent('Apple merchant validation success', {
                        orderId: order.id,
                        ...order,
                    });
                    if (session && session.completeMerchantValidation) {
                        session.completeMerchantValidation(merchantSession);
                    } else {
                        trackEvent('Apple merchant validation failed', {
                            orderId: order.id,
                            ...order,
                        });

                        Sentry.captureException(
                            'Apple merchant validation failed - no session'
                        );
                    }
                }
            );
        };
        session.onpaymentauthorized = (applePayment: any) => {
            trackEvent('Apple payment authorized', {
                orderId: order.id,
                ...order,
            });
            session.completePayment(window.ApplePaySession?.STATUS_SUCCESS);

            fetch(`/api/paymentApple?id=${order?.id}`, {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(applePayment.payment.token),
            })
                .then((response) => {
                    trackEvent('Apple payment server posted', {
                        orderId: order.id,
                        ...order,
                    });
                    if (!response.ok) {
                        trackEvent('Apple payment failed - server request', {
                            response,
                            orderId: order.id,
                            ...order,
                        });
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((result) => {
                    onApplePayCheckoutSuccess(result);
                })
                .catch((e) => {
                    trackEvent(
                        'Apple payment failed - catch - server request',
                        {
                            error: e,
                            orderId: order.id,
                            ...order,
                        }
                    );
                    cl(777, e);
                    onError({ title: 'Apple payment error', error: e });
                });

            navigate(`/${order.id}/wait`, { replace: true });
        };
        session.oncancel = function (event: any) {
            cl(888, 'Payment cancelled');
            trackEvent('Apple payment cancelled', {
                orderId: order.id,
                ...order,
            });
        };

        session.begin();
    }

    const showApplePay = isApplePaySupported && !isTBC;
    const showGooglePay =
        isGooglePaySupported && !isTBC && !isApplePaySupported;
    const showTbcPay = isTbcPaySupported;

    showApplePay && cl('kmg', 'showApplePay', showApplePay);
    showGooglePay && cl('kmg', 'showGooglePay', showGooglePay);
    showTbcPay && cl('kmg', 'showTbcPay', showTbcPay);

    return (
        <PaymentButtonsWrapper>
            {showTbcPay && (
                <TBCPayButton
                    orderId={order?.id || ''}
                    onPaymentSuccess={onTBCCheckoutSuccess}
                    onPaymentFailed={onTBCPaymentFailed}
                    onError={(e) =>
                        onError({ title: 'TBC payment error', error: e })
                    }
                />
            )}
            {showGooglePay && (
                <GooglePayBtn
                    innerClassName={style.pp_gpay_button}
                    orderAmount={order?.amount / 100 || 0}
                    currencyCode={vm?.currency_code}
                    onPaymentCompleted={onGoogleCheckoutSuccess}
                    onError={(e) =>
                        onError({ title: 'Google payment error', error: e })
                    }
                />
            )}
            {showApplePay && (
                <ApplePayButton onClick={onRequestApplePay} text='' />
            )}
        </PaymentButtonsWrapper>
    );
};

export default React.memo(PaymentButtons);
