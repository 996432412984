import { atom, selector } from 'recoil';
import { TIMER_START_VALUE } from '../screensSettings';

import {
    Feedback,
    Order,
    ReceiptData,
    VendingMachine,
    telemetryEventCheck,
    updateFeedback,
} from '../types';

// is it needed?
const qrState = atom<string>({
    key: 'qrState',
    default: '',
});

const nfcState = atom<boolean>({
    key: 'nfcState',
    default: false,
});

const orderState = atom<Order>({
    key: 'orderSate',
    default: {
        id: null,
        company_id: 0,
        user_id: 0,
        vm_id: '',
        voc_id: '',
        pos_id: '',
        cell: null,
        product_id: null,
        device_type: 0,
        payment_system: 0,
        card_type: 0,
        ordered_on: '',
        extra: null,
        merchantId: null,
        src: 0,
        device_status_6: null,
        amount_used: null,
        user_rtrn_id: null,
        date_rtrn: null,
        fiscal_req: null,
        fiscal_success_ID: null,
        fiscal_failed_ID: null,
        qr: '',
        receipt_key: null,

        amount: 0,
        status: 0,
        device_status: null,
    },
});

const vmState = atom<VendingMachine>({
    key: 'vmSate',
    default: {
        id: '',
        // type: 'max',
        name: '',
        vmNumber: '',
        currency_code: 'UAH',
        qr: 'qr',
        type_id: '1',
        merchantIdGooglePlay: 'merchantIdGooglePlay',
        payment_provider_id: 1,
        allowed: 1,
        timeout: 1,
        ct_type: 1,
        max_credit_app: 1,
        status_connected: 1,
        language_code: 'en',
        feedbacks: 0,
        fisc_receipts: 0,
        campaign: {
            message: null,
        },
        vm_id: 0,
        QRservices: 0,
        ads: null
    },
});

const receiptData = atom<ReceiptData>({
    key: 'receiptData',
    default: {
        last_receipt: 1,
        evadts_waiting: 1,
        current_fiscal_datetime_UTC: '',
        current_fiscal_ID: '',
    },
});

const vmDataState = selector({
    key: 'vmDataState',
    get: ({ get }) => {
        const vm = get(vmState);
        const order = get(orderState);
        return { vm, order };
    },
});

const applePaySupportState = atom({
    key: 'applePayState',
    default: false,
});

const googlePaySupportState = atom({
    key: 'googlePayState',
    default: false,
});

const orderProcessingState = atom({
    key: 'orderProcessingState',
    default: false,
});

const timerState = atom({
    key: 'timerState',
    default: TIMER_START_VALUE,
});

const timerVisibility = atom({
    key: 'timerVisibility',
    default: false,
});

// selector for order amount
const orderAmountState = selector({
    key: 'orderAmountState',
    get: ({ get }) => {
        const order = get(orderState);
        return order?.amount;
    },
});

const MaxSate = atom({
    key: 'MaxSate',
    default: 11,
});

const MaxValueState = selector({
    key: 'MaxValueState',
    get: ({ get }) => {
        const max = get(MaxSate);
        const order = get(orderState);
        return { max, order };
    },
});

const pageNumber = atom({
    key: 'pageNumber',
    default: '1/3',
});

const pageNumberVisibility = atom({
    key: 'pageNumberVisibility',
    default: false,
});

const feedbackState = atom<Feedback>({
    key: 'feedbackState',
    default: {
        chosen_options: null,
        cluster_id: 1,
        custom_content: null,
        customer_name: null,
        customer_phone_number: null,
        customer_status: null,
        estimate: null,
        id: 7,
        inserted: '',
        pos_id: 1,
        prj_id: 1,
        transaction_id: null,
        updated: '',
        user_comment: null,
        user_priority: 1,
        questions: [
            {
                1: 'comment',
            },
        ],
        user_status: 1,
        vm_id: 1,
        voc_id: 1,
        wallet_user_id: null,
    },
});

const updateFeedbackState = atom<updateFeedback>({
    key: 'updateFeedbackState',
    default: {
        chosen_options: null,
        custom_content: null,
        customer_name: null,
        customer_phone_number: null,
        customer_status: null,
        estimate: null,
        id: 1,
    },
});

const telemetryEvent = atom<telemetryEventCheck>({
    key: 'telemetryEvent',
    default: {
        id: null,
    },
});

export {
    applePaySupportState,
    feedbackState,
    googlePaySupportState,
    nfcState,
    orderAmountState,
    orderProcessingState,
    orderState,
    // MaxValueState,
    pageNumber,
    pageNumberVisibility,
    qrState,
    receiptData,
    telemetryEvent,
    timerState,
    timerVisibility,
    updateFeedbackState,
    vmDataState,
    vmState,
};
