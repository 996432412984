import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../common/PrimaryButton';
import { vmState } from '../../state/recoilState';
import { VendingMachine } from '../../types';
import { useRecoilValue } from 'recoil';
import { QR_SERVICES } from '../../constants';

interface FeedbackProps {
  onFeedbackClick?: () => void;
  showFeedback?: boolean;
}

const FeedbackCustom = (props: FeedbackProps) => {
  const {
    showFeedback,
    onFeedbackClick
  } = props;
  const { t } = useTranslation();
  const vm = useRecoilValue<VendingMachine>(vmState);
  const [isFeedbacksActive, setIsFeedbacksActive] = useState<boolean>(Boolean(showFeedback));
  

  useEffect(() => {
    if (vm.QRservices === QR_SERVICES.SEPARATED_SERVICES && vm.allowed === 1) {
      setIsFeedbacksActive(false);
    }
  }, []);
  return (
    <>
    {isFeedbacksActive && (
        <PrimaryButton type="button" onClick={onFeedbackClick}>
          {t('Feedback')}
        </PrimaryButton>
      )}
    </>
      
  );
};
export default FeedbackCustom;
