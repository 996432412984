import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { vmState, receiptData } from '../../../state/recoilState';
import { ReceiptData } from '../../../types';
import ContentBox from '../../common/ContentBox';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';
import PrimaryButton from '../../common/PrimaryButton';
import { saveAs } from 'file-saver';




const ServiceFail = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [vm, setVm] = useRecoilState(vmState);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useRecoilState<ReceiptData>(receiptData);


    const url = `/api/get_check?k=${data.current_fiscal_ID}`; 

    const getReceipt = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response.status === 200) {
                const arrayBuffer = await response.arrayBuffer();
                const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                const newTab = window.open(fileURL, '_blank');
                saveAs(blob, 'receipt.pdf');
            } else {
                navigate(`/${vm.qr}/servicefail`, { replace: true });
                throw new Error(response.statusText);
            }
        } catch (error) {
            console.error('Request failed:', error);
            navigate(`/${vm.qr}/servicefail`, { replace: true });
        } finally {
            setIsLoading(false);
        }
    };


    const onMainPage = (): void => {
        navigate(`/${vm.qr}/servicemain`, { replace: true });
    };



    return (
        <ScreenWrapper>
            {isLoading ? (<ScreenWrapper>
                <ScreenTitle>{t('Wait')} </ScreenTitle>
                <ContentBox>
                    <object id='vm' data='/vm.html' />
                </ContentBox></ScreenWrapper>)
                : (<ScreenWrapper>
                    <ScreenTitle>{t('Thank for service')} </ScreenTitle>
                    <object data='/tick.html' />
                    <PrimaryButton onClick={getReceipt}>
                        {t('Download receipt')}
                    </PrimaryButton>
                    <PrimaryButton onClick={onMainPage}>
                        {t('Main page')}
                    </PrimaryButton>
                </ScreenWrapper>)
            }
        </ScreenWrapper>
    );
};

export default ServiceFail;