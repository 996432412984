import { Outlet } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import Footer from './Footer/Footer';
import Header from './Header/Header';

export const Layout = () => {
    return (
        <>
            <Header />
            <main>
                <ErrorBoundary>
                    <Outlet />
                </ErrorBoundary>
            </main>
            <Footer />
        </>
    );
};
