import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
    DISABLE_OR_ENABLE_BUTTON,
    FEEDBACKS_CUSTOMER_STATUS,
} from '../../../constants';
import {
    feedbackState,
    pageNumber,
    pageNumberVisibility,
    updateFeedbackState,
    vmState,
} from '../../../state/recoilState';
import {
    BasicScreenProps,
    Feedback,
    VendingMachine,
    updateFeedback,
} from '../../../types';
import { updateFeedbacks } from '../../../utils/general';
import ContentBox from '../../common/ContentBox';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';
import { CustomTextField } from './components/CustomTextField';
import {
    FeedbacksButton,
    FeedbacksButtonBox,
} from './components/FeedbacksStyles';

interface WishesPage extends BasicScreenProps {
    onCancel?: () => void;
}

interface FieldsDataState {
    Wishes: string;
}

const WishesPage = (props: WishesPage) => {
    const vm = useRecoilValue<VendingMachine>(vmState);
    const [feedback, setFeedback] = useRecoilState<Feedback>(feedbackState);
    const [updatedFeedback, setUpdateFeedback] =
        useRecoilState<updateFeedback>(updateFeedbackState);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [notes, setNotes] = useState<FieldsDataState>({
        Wishes: '',
    });
    const setNumberVisibility = useSetRecoilState(pageNumberVisibility);
    const [page, setPageNumber] = useRecoilState<string>(pageNumber);
    const [error, setError] = useState<boolean>(false);
    const isNextButtonEnabled = notes.Wishes == '';
    const nextButtonStyles =
        isNextButtonEnabled || error
            ? DISABLE_OR_ENABLE_BUTTON[1]
            : DISABLE_OR_ENABLE_BUTTON[0];
    const newFeedback = {
        ...updatedFeedback,
        custom_content: notes.Wishes ? notes.Wishes : null,
        customer_status: FEEDBACKS_CUSTOMER_STATUS.AFTER_WISHES_PAGE,
    };

    useEffect(() => {
        setPageNumber('2/3');
        setNumberVisibility(true);
    }, []);

    const handleNotesChange = (event: {
        target: { id: string; value: string };
    }) => {
        const { id, value } = event.target;
        setNotes({
            Wishes: id === 'Wishes' ? value : notes.Wishes,
        });
    };

    const onNextPage = () => {
        setUpdateFeedback(newFeedback);
        updateFeedbacks(feedback.id, newFeedback);
        navigate(`/${vm.qr}/contactpage`);
    };

    const onCancelClick = () => {
        setNumberVisibility(false);
        navigate(`/${vm.qr}/completepage`, { replace: true });
    };

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Your wishes')}</ScreenTitle>
            <ContentBox>
                <CustomTextField
                    id='Wishes'
                    label={t('Your comment')}
                    onChange={handleNotesChange}
                    rows={5}
                    setGlobalError={setError}
                />

                <FeedbacksButtonBox>
                    <FeedbacksButton onClick={onCancelClick}>
                        {t('Cancel')}
                    </FeedbacksButton>
                    <FeedbacksButton
                        disabled={isNextButtonEnabled || error}
                        onClick={onNextPage}
                        style={nextButtonStyles}
                    >
                        {t('Next')}
                    </FeedbacksButton>
                </FeedbacksButtonBox>
            </ContentBox>
        </ScreenWrapper>
    );
};

export default WishesPage;
