import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { orderState } from '../../../state/recoilState';
import { BasicScreenProps } from '../../../types';
import { trackPageView } from '../../../utils/tracking';
import { Button } from '../../common/Button';
import ContentBox from '../../common/ContentBox';
import PrimaryButton from '../../common/PrimaryButton';
import SVGObject from '../../common/SVGObject';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';

interface ErrorScreenProps {
    // onRepeatPurchase: () => void;
    title?: string;
}

const Error404Screen = (props: ErrorScreenProps) => {
    const { title } = props;
    const { t } = useTranslation();
    const order = useRecoilValue(orderState);

    useEffect(() => {
        trackPageView(title || 'Error 404', {
            url: window.location.href,
            orderId: order.id,
            ...order,
        });
        Sentry.captureMessage('Error 404. URL:' + window.location.href);
    }, []);

    return (
        <ScreenWrapper>
            <ScreenTitle>
                {t(title || 'Ooops. Error 404. No such page')}
            </ScreenTitle>
            <ContentBox>
                <SVGObject
                    src='/cross.html'
                    style={{
                        // marginTop: "-2vmax",
                        height: '230px',
                        width: 'min(100vw, 500px)',
                    }}
                />

                {/* <Button onClick={onRepeatPurchase}>
                    {t('Repeat_purchase')}
                </Button> */}
            </ContentBox>
        </ScreenWrapper>
    );
};

export default Error404Screen;
