import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isDev } from '../../../constants';
import { receiptData, vmState } from '../../../state/recoilState';
import { BasicScreenProps, ReceiptData } from '../../../types';
import ContentBox from '../../common/ContentBox';
import PrimaryButton from '../../common/PrimaryButton';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';

interface ServiceRobot extends BasicScreenProps {}

const CanvasObject = styled.object`
    margin: 0 0 2vh 0;
    width: 100%;
    background: #edecef;
    display: block;
    align-items: center;
    min-width: 90px;
    max-width: 199px;
    top: 30vh;
`;

const ServiceRobot = (props: ServiceRobot) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [vm, setVm] = useRecoilState(vmState);
    const [data, setData] = useRecoilState<ReceiptData>(receiptData);

    const onMainPage = (): void => {
        navigate(`/${vm.qr}/servicemain`, { replace: true });
    };

    const urlGetReceipt = isDev
        ? '/dev/get_fisc_data.json'
        : `/api/get_fisc_data?k=${vm.vm_id}`;

    async function funcReceipt(url: string): Promise<void> {
        try {
            const response = await fetch(url, {
                method: 'POST',
            });
            if (response.status === 200) {
                const result: ReceiptData = await response.json();
                const {
                    current_fiscal_datetime_UTC,
                    last_receipt,
                    evadts_waiting,
                    current_fiscal_ID,
                } = result;
                setData(result);

                const timeNowUTC = new Date().toISOString().substring(0, 19); // transform time to UTC => sec
                const timeNow = Date.parse(timeNowUTC) / 1000; // time now in sec
                const timeReceipt =
                    Date.parse(current_fiscal_datetime_UTC) / 1000; //time from DB in sec
                const isValidReceipt = timeNow - timeReceipt <= last_receipt;
                const path = isValidReceipt ? 'servicecomplete' : 'servicewait';
                navigate(`/${vm.qr}/${path}`, { replace: true });
            } else {
                navigate(`/${vm.qr}/servicefail`, { replace: true });
                console.warn('Something goes wrong');
                throw new Error(response.statusText);
            }
        } catch (error) {
            console.error('Request failed:', error);
            navigate(`/${vm.qr}/servicefail`, { replace: true });
        }
    }

    const handleCaptchaVerify = (response: string | null) => {
        if (response) {
            funcReceipt(urlGetReceipt);
        }
    };

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Robot')} </ScreenTitle>
            <ContentBox>
                <CanvasObject data='/data.html' type='text/html' />

                <ReCAPTCHA
                    hl={vm.language_code}
                    sitekey={`${import.meta.env.VITE_PUBLIC_CAPTCHA_KEY}`}
                    onChange={handleCaptchaVerify}
                    size='normal'
                />
            </ContentBox>
            <PrimaryButton onClick={onMainPage}>{t('Main page')}</PrimaryButton>
        </ScreenWrapper>
    );
};

export default ServiceRobot;
