import styled from 'styled-components';

const SVGObject = styled.iframe`
  width: 100%;
  height: 30vh;
  // object-fit: contain;
  overflow: hidden;
  scrollbars: none;
  background-color: #edecef;
  border: none;
`;

export default SVGObject;
