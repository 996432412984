import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicScreenProps } from '../../../types';
import { screenLog } from '../../../utils/general';
import { trackPageView } from '../../../utils/tracking';
import { Button } from '../../common/Button';
import ContentBox from '../../common/ContentBox';
import FeedbackCustom from '../../common/FeedbackButtonLogic';
// import PrimaryButton from '../../common/PrimaryButton';
import SVGObject from '../../common/SVGObject';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';

interface NotConnectedScreenProps extends BasicScreenProps {
    onRepeatPurchase: () => void;
}

const NotConnectedScreen = (props: NotConnectedScreenProps) => {
    const { showFeedback, onFeedback, onRepeatPurchase } = props;
    const { t } = useTranslation();

    useEffect(() => {
        trackPageView('not connected');
        screenLog('NotConnected Screen');
    }, []);

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Try again')}</ScreenTitle>
            <ContentBox>
                <SVGObject id='vm' src='/cross.html' />
                <FeedbackCustom
                    showFeedback={showFeedback}
                    onFeedbackClick={onFeedback}
                />
                <Button onClick={onRepeatPurchase}>
                    {t('Repeat_purchase')}
                </Button>
            </ContentBox>
        </ScreenWrapper>
    );
};

export default NotConnectedScreen;
