import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import {
    pageNumber,
    pageNumberVisibility,
    timerState,
    timerVisibility,
} from '../../state/recoilState';
import CookieBanner from './CookieBanner/CookieBanner';
import { VersionStructure } from '../../types';
import { getPrivacyVersion } from '../../utils/general';

const Logo = styled.img`
    width: 30vw;
    max-width: 15vh;
    margin: 3vh 1vh 1vh 2vh;
    aspect-ratio: auto;
`;

const HeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10vh;
    max-height: 10vh;
    color: #1b3f92;
    align-self: flex-start;
`;

const Timer = styled.div`
    font-size: 2.5vh;
    font-weight: bold;
    margin: 0 3vh 0 0;
    width: 28px;
    margin-bottom: ${(props: { cookie: any }) => (props.cookie ? '0' : '65px')};
`;

const MainBox = styled.div`
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
    margin-top: 40px;
    height: 110px;
`;

const Header = () => {
    const showTimer = useRecoilValue(timerVisibility);
    const timer = useRecoilValue(timerState);
    const showPageNumber = useRecoilValue(pageNumberVisibility);
    const page = useRecoilValue(pageNumber);
    const [anchorEl, setAnchorEl] = useState(null);

    const [showCookiesBanner, setShowCookiesBanner] = useState(false);
    const defaultVersion = {
        Offert: '0000-00-00',
        PP: '0000-00-00',
        ToU: '0000-00-00',
    };
    const [versions, setVersions] = useState<VersionStructure>(defaultVersion);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const onSubmitCookie = () => {
        const jsonData = JSON.stringify(versions);
        document.cookie = `cookieBanner=${jsonData}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
        setShowCookiesBanner(false);
    };
    useEffect(() => {
        getPrivacyVersion(setShowCookiesBanner, setVersions);
    }, []);

    return (
        <HeaderBox className="header">
            <div className="logo">
                <Logo src="/new_logo.svg" alt="ProstoPay logo" />
            </div>
            <MainBox>
                {showCookiesBanner && (
                    <CookieBanner
                        handleClick={handleClick}
                        id="simple-popover"
                        open={open}
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        onSubmitCookie={onSubmitCookie}
                    />
                )}
                {showTimer && <Timer cookie={showCookiesBanner}>{timer}</Timer>}
                {showPageNumber && (
                    <Timer cookie={showCookiesBanner}>{page}</Timer>
                )}
            </MainBox>
        </HeaderBox>
    );
};

export default Header;
