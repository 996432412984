import * as amplitude from '@amplitude/analytics-browser';

export const trackPageView = (
    pageName: string,
    details: any = {},
    url = window.location.href
) => {
    const { orderId, userId } = details;
    amplitude.track('pageview', { pageName, url });
};

export const trackEvent = (eventName: string, eventProperties?: any) => {
    amplitude.track(eventName, eventProperties);
};

export const trackClick = (targetName: string, details?: any) => {
    amplitude.track('click', { targetName, details });
};
