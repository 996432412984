import { TextField } from '@mui/material';
import { useState } from 'react';

export function CustomTextField(props: any) {
  const { id, label, onChange, value, rows, setGlobalError } = props;
  const [error, setError] = useState<any>(false);

  const handleInputChange = (event: { target: { value: string } }) => {
    if (event.target.value.length <= 300) {
      onChange(event);
      setError(false);
      setGlobalError(false);
    } else {
      setError(true);
      setGlobalError(true);
    }
  };
  return (
    <TextField
      id={id}
      label={label}
      variant="outlined"
      type="number"
      sx={{
        width: '100%',
        maxWidth: '400px',
        marginBottom: '2vh',
        '& .MuiOutlinedInput-root': {
          borderRadius: '18px',
          boxShadow: 'rgba(0, 0, 0, 0.3) 0px 15px 15px',
        },
      }}
      value={value}
      onChange={handleInputChange}
      multiline
      rows={rows}
      error={error}
      helperText={error && 'Не більше 300 символів'}
    />
  );
}
