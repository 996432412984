import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import useTimer from '../../../hooks/useTimer';
import {
    orderState,
    qrState,
    vmDataState,
    vmState,
} from '../../../state/recoilState';
import {
    BasicScreenProps,
    Order,
    ScreenWithTimerProps,
    VendingMachine,
} from '../../../types';
import { cl, screenLog } from '../../../utils/general';
import { trackPageView } from '../../../utils/tracking';
import { Button } from '../../common/Button';
import ContentBox from '../../common/ContentBox';
import FeedbackCustom from '../../common/FeedbackButtonLogic';
import PaymentButtons from '../../common/PaymentButtons/PaymentButtons';
import PrimaryButton from '../../common/PrimaryButton';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';

interface PaymentScreenProps extends ScreenWithTimerProps {
    vm?: VendingMachine;
    order?: Order;
}

const CardImage = styled.img`
    margin: 1vh 0;
    width: min(24vw, 160px);
    height: min(21.8vw, 146px);
`;

const PriceBox = styled.div`
    margin: 1vh 0 1rem;
    font-size: min(9.4vw, 61px);
    font-weight: bold;
    color: #1b3f92;
`;

const PromoText = styled.div`
    margin: 1vh 0 0 0;
    font-size: min(3.9vw, 25px);
    font-weight: medium;
    color: #f77f00;
`;

const PaymentScreen = (props: PaymentScreenProps) => {
    const { showFeedback, onFeedback, onCancel, onTimerTimeoutHandler } = props;
    const { t } = useTranslation();
    const timerHook = useTimer();

    const vm = useRecoilValue(vmState);
    const vmValue = useRecoilValue(vmDataState);
    const [order] = useRecoilState(orderState);
    const [showPaymentButton, setShowPaymentButton] = useState<boolean>(true);

    useEffect(() => {
        trackPageView('payment', { orderId: order.id, ...order });
        screenLog('Payment');
        timerHook.start();

        return () => {
            timerHook.reset();
        };
    }, []);

    useEffect(() => {
        if (timerHook.time <= 0) {
            //redirect to home
            onTimerTimeoutHandler && onTimerTimeoutHandler();
            // timerHook.reset();
        }
    }, [timerHook.time]);

    useEffect(() => {
        //order change
        if (order) {
            cl('order change', order.amount);
        }
    }, [order]);

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Order payment')}</ScreenTitle>
            <ContentBox>
                <CardImage src='/card.svg' alt='.' />

                <PriceBox>
                    <span id='price'>
                        {(vmValue.order?.amount / 100).toFixed(2)}
                    </span>{' '}
                    {t(`Currency.${vm?.currency_code}`)}
                </PriceBox>

                {vm?.campaign?.message && (
                    <PromoText>{vm?.campaign?.message}</PromoText>
                )}

                {showPaymentButton && <PaymentButtons />}

                <FeedbackCustom
                    onFeedbackClick={onFeedback}
                    showFeedback={false}
                />

                <Button onClick={onCancel}>{t('Cancel')}</Button>
            </ContentBox>
        </ScreenWrapper>
    );
};

export default PaymentScreen;
