import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import style from './TBCPayButton.module.css';
import { fetchData } from '../../../../utils/general';
import { trackClick } from '../../../../utils/tracking';

interface TBCPayButtonProps {
    orderId: string;
    onPaymentSuccess: (result: any) => void;
    onPaymentFailed: () => void;
    onError: (error: any) => void;
}

const TBCPayButton = ({
    orderId,
    onPaymentSuccess,
    onPaymentFailed,
    onError,
}: TBCPayButtonProps) => {
    const { t } = useTranslation();

    const fetchDataURL = `/api/paymentTbcStart?id=${orderId}`;

    function onRequestTbcPayment() {
        trackClick('TBC Button', { orderId });
        fetchData(fetchDataURL, onPaymentSuccess, onPaymentFailed);
    }

    return (
        <button
            className={`${style.pay_button}  tbc-checkout-pay ${style.tbcCheckoutPay}`}
            id='tbcCheckoutButton'
            onClick={onRequestTbcPayment}
        >
            <div className={style.tbcCheckoutButtonLogo}>
                <img
                    alt='TBC Bank'
                    height='24'
                    src='/images/tbc-logo-white.svg'
                />
                <span>{t('Pay')}</span>
            </div>
            <div className={style.tbcCheckoutCardIcons}>
                <img alt='Visa' src='/images/visa.svg' />
                <img alt='Mastercard' src='/images/mc.svg' />
                <img alt='QR' src='/images/QR.svg' />
            </div>
        </button>
    );
};

export default TBCPayButton;
