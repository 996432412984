import styled from 'styled-components';

const InputValidationMsg = styled.div`
  font-weight: 400;
  color: #5c81ed;
  text-align: center;
  font-size: min(3.7vw, 24px);
  min-height: 2rem;
`;

export default InputValidationMsg;
