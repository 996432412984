import styled from 'styled-components';

const ErrorText = styled.div`
  // margin: 1vh 0 0 0;
  font-size: min(3.9vw, 26px);
  font-weight: medium;
  color: #f77f00;
  text-align: center;
`;

export default ErrorText;
