import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicScreenProps } from '../../../types';
import { trackPageView } from '../../../utils/tracking';
import ContentBox from '../../common/ContentBox';

// import Button from '@mui/material/Button';
import { STORAGE_KEYS } from '../../../constants';
import { screenLog } from '../../../utils/general';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';

interface OldTabScreenProps extends BasicScreenProps {
    title?: string;
}

const OldTabScreen = (props: OldTabScreenProps) => {
    const { title } = props;
    const { t } = useTranslation();

    useEffect(() => {
        trackPageView(title || 'old tab');
        screenLog('OldTab Screen');
        sessionStorage.removeItem(STORAGE_KEYS.TAB_EXPIRED);
    }, []);

    return (
        <ScreenWrapper>
            <ScreenTitle>{t(title || 'Failed sale')}</ScreenTitle>
            <ContentBox>
                {/* <Button
                    onClick={() => {
                        throw new Error('Sentry test');
                    }}
                >
                </Button> */}
            </ContentBox>
        </ScreenWrapper>
    );
};

export default OldTabScreen;
