import styled from 'styled-components';

const Input = styled.input`
  min-height: 45px;
  width: 65vw;
  padding: min(2%, 12px) min(3%, 18px);
  font-size: min(16px, 26px);
  border: none;
  background: #fdffff;
  border-radius: 18px;
  box-shadow: 0 15px 15px rgb(0 0 0 / 30%);
  outline: none;
  margin: 0 0 1rem;
  color: #1b3f92;
`;

export default Input;
