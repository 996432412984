import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    API,
    CT_TYPES,
    DEVICE_STATUS,
    MACHINE_TYPES,
    ORDER_STATUS,
    isDev,
} from '../../../constants';
import useTimer from '../../../hooks/useTimer';
import { orderState, timerState, vmState } from '../../../state/recoilState';
import { Order, ScreenWithTimerProps, VendingMachine } from '../../../types';
import { getSelectScreenDevUrl } from '../../../utils/dev';
import { cl, fetchData, getVmDetails, screenLog } from '../../../utils/general';
import { trackPageView } from '../../../utils/tracking';
import ButtonBox from '../../common/ButtonBox';
import ContentBox from '../../common/ContentBox';
import FeedbackAndReceipt from '../../common/FeedbackAndReceipt';
import PrimaryButton from '../../common/PrimaryButton';
import SVGObject from '../../common/SVGObject';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';

// Logic of this screen
// 1. In the loop till timer is not 0 we check if product is selected
// 2. If product is selected in the responce device_status is 3
// 3. If device_status is 3 we go to the next screen

type SelectProductsProps = ScreenWithTimerProps;

const SelectProduct = (props: SelectProductsProps) => {
    const {
        showFeedback,
        onFeedback,
        onCancel,
        onTimerTimeoutHandler,
        showReceipt,
        onGetReceipt,
    } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const timerHook = useTimer(); // TODO rename to timer

    const vm = useRecoilValue<VendingMachine>(vmState);
    const [order, setOrder] = useRecoilState<Order>(orderState);
    const [isProductSelected, setIsProductSelected] = useState<boolean>(false);

    const { isCT5 } = getVmDetails(vm);

    useEffect(() => {
        trackPageView('select product', { orderId: order.id, ...order });
        screenLog('Select product');
        timerHook.start();

        return () => {
            timerHook.reset();
        };
    }, []);

    useEffect(() => {
        // wait for user selection
        if (timerHook.time <= 0) {
            onTimerTimeoutHandler && onTimerTimeoutHandler();
        } else {
            if (!order.id) {
                Sentry.captureException(
                    'order id is not set on selection screen'
                );
                navigate('/selection/error', { replace: true });
            } else if (!isProductSelected) {
                fetchData(
                    isDev
                        ? getSelectScreenDevUrl(timerHook.time)
                        : `${API.ORDER}?id=${order.id}&t=${timerHook.time}`,
                    (orderData: Order) => {
                        cl('order data fetched', orderData);

                        if (
                            orderData.device_status ===
                            DEVICE_STATUS.PRODUCT_SELECTED
                        ) {
                            setOrder(orderData);
                            setIsProductSelected(true);

                            const page = isCT5 ? 'wait' : 'payment';

                            setTimeout(() => {
                                navigate(`/${orderData.id}/${page}`, {
                                    replace: true,
                                });
                            }, 500);
                        }

                        // rejection from device
                        if (
                            orderData.device_status ===
                                DEVICE_STATUS.COMPLETED &&
                            orderData.status === ORDER_STATUS.REFUNDED
                        ) {
                            navigate(`/${orderData.id}/refund`, {
                                replace: true,
                            });
                        }
                    },
                    (error: any) => {
                        cl('some error during fetching order data');
                        error.orderId = order.id;
                        Sentry.captureException(error);
                        navigate(`/${order.id ?? 'order'}/error`, {
                            replace: true,
                        });
                    }
                );
            }
        }
    }, [timerHook.time]);

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Select product')}</ScreenTitle>
            <ContentBox>
                <SVGObject className='food' src='/food_choice.html' />
                {/* <iframe
          name="mk"
          src="/food_choice.html"
          style={{
            width: '100%',
            height: '30vh',
            border: 'none',
            background: '#edecef',
          }}
        /> */}

                <FeedbackAndReceipt
                    showReceipt={showReceipt}
                    showFeedback={showFeedback}
                    onCancelClick={onCancel}
                    onReceiptClick={onGetReceipt}
                    onFeedbackClick={onFeedback}
                />

                {/* <Button onClick={onCancel}>{t('Cancel')}</Button> */}
            </ContentBox>
        </ScreenWrapper>
    );
};

export default SelectProduct;
