import { FormGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
    DISABLE_OR_ENABLE_BUTTON,
    FEEDBACKS_CUSTOMER_STATUS,
} from '../../../constants';
import {
    feedbackState,
    pageNumber,
    pageNumberVisibility,
    updateFeedbackState,
    vmState,
} from '../../../state/recoilState';
import {
    BasicScreenProps,
    Feedback,
    VendingMachine,
    updateFeedback,
} from '../../../types';
import { updateFeedbacks } from '../../../utils/general';
import ContentBox from '../../common/ContentBox';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';
import { CustomCheckbox } from './components/CustomCheckbox';
import { CustomTextField } from './components/CustomTextField';
import {
    FeedbacksButton,
    FeedbacksButtonBox,
    FormGroupBox,
} from './components/FeedbacksStyles';

interface CheckboxPage extends BasicScreenProps {
    onCancel?: () => void;
}
const CheckboxPage = (props: CheckboxPage) => {
    const vm = useRecoilValue<VendingMachine>(vmState);
    const [feedback, setFeedback] = useRecoilState<Feedback>(feedbackState);
    const [updatedFeedback, setUpdateFeedback] =
        useRecoilState<updateFeedback>(updateFeedbackState);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const questionsArray = feedback?.questions;
    const [selectedIds, setSelectedIds] = useState([]);
    const [checkboxState, setCheckboxState] = useState<{}>({});
    const [inputValue, setInputValue] = useState({
        comment: '',
    });
    const setNumberVisibility = useSetRecoilState(pageNumberVisibility);
    const [page, setPageNumber] = useRecoilState<string>(pageNumber);
    const isNextButtonEnabled =
        Object.values(checkboxState).some((value) => value) ||
        inputValue.comment.trim() !== '';
    const [error, setError] = useState<boolean>(false);
    const nextButtonStyles =
        isNextButtonEnabled && !error
            ? DISABLE_OR_ENABLE_BUTTON[0]
            : DISABLE_OR_ENABLE_BUTTON[1];
    const newFeedbacks = {
        ...updatedFeedback,
        chosen_options: selectedIds ? JSON.stringify(selectedIds) : null,
        custom_content: inputValue.comment ? inputValue.comment : null,
        customer_status: FEEDBACKS_CUSTOMER_STATUS.AFTER_WISHES_PAGE,
    };

    useEffect(() => {
        setPageNumber('2/3');
        setNumberVisibility(true);
        const initialCheckboxState: any = {};
        feedback.questions.forEach((question) => {
            initialCheckboxState[question.id] = false;
        });
        setCheckboxState(initialCheckboxState);
    }, []);

    const handleCheckboxChange = (event: {
        target: { id: string; checked: boolean };
    }) => {
        const { checked } = event.target;
        const id = parseInt(event.target.id, 10);
        setCheckboxState((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
        setSelectedIds((prevSelectedIds: any) => {
            if (checked) {
                if (!prevSelectedIds.includes(id)) {
                    return [...prevSelectedIds, id];
                }
            } else {
                return prevSelectedIds.filter(
                    (selectedId: any) => selectedId !== id
                );
            }
            return prevSelectedIds;
        });
    };

    const handleInputChange = (event: {
        target: { id: string; value: string };
    }) => {
        setInputValue({
            comment:
                event.target.id === 'comment'
                    ? event.target.value
                    : inputValue.comment,
        });
    };

    const onContactPage = () => {
        setUpdateFeedback(newFeedbacks);
        updateFeedbacks(feedback.id, newFeedbacks);
        navigate(`/${vm.qr}/contactpage`);
    };

    const onCancelClick = () => {
        setNumberVisibility(false);
        navigate(`/${vm.qr}/completepage`, { replace: true });
    };

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('What didn\'t you like?')}</ScreenTitle>
            <ContentBox>
                <FormGroupBox>
                    <FormGroup>
                        {questionsArray.map((question) => (
                            <CustomCheckbox
                                id={question.id}
                                onChange={handleCheckboxChange}
                                label={t(question.question)}
                            />
                        ))}
                    </FormGroup>
                </FormGroupBox>

                <CustomTextField
                    id='comment'
                    label={t('Your comment')}
                    value={inputValue.comment}
                    onChange={handleInputChange}
                    rows={1.5}
                    setGlobalError={setError}
                />
                <FeedbacksButtonBox>
                    <FeedbacksButton onClick={onCancelClick}>
                        {t('Cancel')}
                    </FeedbacksButton>
                    <FeedbacksButton
                        onClick={onContactPage}
                        disabled={!isNextButtonEnabled || error}
                        style={nextButtonStyles}
                    >
                        {t('Next')}
                    </FeedbacksButton>
                </FeedbacksButtonBox>
            </ContentBox>
        </ScreenWrapper>
    );
};

export default CheckboxPage;
