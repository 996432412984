import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';
// import { RecoilURLSyncJSON } from 'recoil-sync';
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';

import App from './App';
import './i18n';

import { isDev, isProd, isStage } from './constants';
import { MACHINES_UNDER_ANALYSIS } from './settings';
import { qrState } from './state/recoilState';
import { cltext, getUrlParams } from './utils/general';

import './index.css';

if (isProd || isStage) {
    const env = isProd ? 'production' : 'staging';

    Sentry.init({
        dsn: 'https://b14f7d78376341529a88569d343c74d0@o4505274474561536.ingest.sentry.io/4505274476986368',
        release: 'WebQR@' + APP_VERSION + ` [${env}]`,
        environment: env,
        integrations: [
            // Sentry.browserTracingIntegration(),
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.metrics.metricsAggregatorIntegration(),
            // Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 0.2,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/pay.prostopay\.net\/api/,
        ],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

const amplitudeKey = isProd
    ? import.meta.env.VITE_AMPLITUDE_KEY
    : import.meta.env.VITE_AMPLITUDE_KEY_DEV;

const initAmplitude = () => {
    if (isDev || isStage) {
        cltext('amplitude init skipped on dev / stage env', 'orange');
        return;
    }

    // make amplitude work only on every third days
    // const today = new Date();
    // if (today.getDate() % 3 !== 0) {
    //     return;
    // }

    amplitude.init(amplitudeKey, undefined, {
        flushQueueSize: 1,
        flushIntervalMillis: 1000,
        useBatch: false,
        defaultTracking: {
            pageViews: false,
        },
    });
};

{
    // init amplitude for machines under analysis
    // if QR is one of analyzed machines
    const urlQr = getUrlParams('qr');

    cltext(`urlQr (new): ${urlQr}`, 'orange');

    if (MACHINES_UNDER_ANALYSIS.includes(urlQr ?? '')) {
        cltext(`machine ${urlQr} is under 🔎`, 'orange');
        // TODO think about singleton for amplitude
        initAmplitude();
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initializeState = ({ set }: { set: any }) => {
    const urlQr = getUrlParams('qr');
    set(qrState, urlQr ?? '');

    cltext('initialize sate');
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <>
        {/* <React.StrictMode> */}
        <Sentry.ErrorBoundary>
            <RecoilRoot initializeState={initializeState}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </RecoilRoot>
        </Sentry.ErrorBoundary>
        {/* </React.StrictMode> */}
    </>
);
