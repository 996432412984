import styled from 'styled-components';

const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  color: #ccc;
`;

export default ContentBox;
