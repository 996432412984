import { useSetRecoilState, useRecoilState } from 'recoil';
import { TIMER_START_VALUE } from '../screensSettings';
import { timerVisibility, timerState } from '../state/recoilState';
import { useEffect } from 'react';
import { cl } from '../utils/general';
import { trackEvent } from '../utils/tracking';

const useTimer = (duration?: number) => {
    const setShowTimer = useSetRecoilState(timerVisibility);
    const [timer, setTimer] = useRecoilState<number>(timerState);
    let timerInterval: any = null;

    if (duration) {
        useEffect(() => {
            setTimer(duration);
        }, [duration]);
    }

    useEffect(() => {
        return () => {
            cl('timer reset in hook cleanup');
            clearInterval(timerInterval);
        };
    }, []);

    useEffect(() => {
        if (timer <= 0) {
            trackEvent('timer timeout');
            cl('timer reset in hook effect', timerInterval);
            clearInterval(timerInterval);
            setShowTimer(false);
            setTimer(TIMER_START_VALUE);
        }
    }, [timer]);

    return {
        time: timer,
        start: () => {
            setShowTimer(true);
            timerInterval = setInterval(() => {
                setTimer((timer) => timer - 1);
            }, 1000);
        },
        reset: () => {
            cl('timer reset in hook', timerInterval);
            clearInterval(timerInterval);
            setShowTimer(false);
            setTimer(TIMER_START_VALUE);
        },
    };
};

export default useTimer;
