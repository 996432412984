import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BasicScreenProps, VendingMachine } from '../../../types';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
    vmState,
} from '../../../state/recoilState';
import ContentBox from '../../common/ContentBox';
import PrimaryButton from '../../common/PrimaryButton';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';
import style from './ServiceMain.module.css';
import { useState, useEffect } from 'react';
import { QR_SERVICES, MACHINE_TYPES } from '../../../constants';

interface ServiceMain extends BasicScreenProps {
    onRepeatPurchase?: () => void;
    isAbleToFeedback?: boolean;
    isAbleToReceipt?: boolean;
    onFeedback?: () => void;
}

const CanvasObject = styled.object`
    margin: 1vh;
    width: 100%;
    background: #edecef;
    display: block;
  min-width: 90px;
  max-width: 199px;  
`;

const ServiceMain = (props: ServiceMain) => {
    const {
        onRepeatPurchase,
        onFeedback
    } = props;
    const vm = useRecoilValue<VendingMachine>(vmState);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const getReceipt = () => {
        navigate(`/${vm.qr}/servicerobot`, { replace: true });
    };

    const isReceiptActive = Boolean(vm.fisc_receipts);
    const isFeedbacksActive = Boolean(vm.feedbacks);
    const [isPaymentActive, setIsPaymentActive] = useState<boolean>(Boolean(vm.allowed));

    useEffect(() => {
        if(vm.QRservices === QR_SERVICES.SEPARATED_SERVICES){
            setIsPaymentActive(false);
        }
        if(vm.QRservices === QR_SERVICES.ALL_IS_PERMITTED && vm.type_id === MACHINE_TYPES.FRIDGE){
            setIsPaymentActive(true);
        }
    }, []);

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Menu')} {vm?.name} {vm?.vmNumber}</ScreenTitle>
            <ContentBox>

                <CanvasObject className={style.svg} data='/Component.png' />

                {isReceiptActive && <PrimaryButton onClick={getReceipt}>
                    {t('Get fiscal receipt')}
                </PrimaryButton>}

                {isFeedbacksActive && <PrimaryButton onClick={onFeedback}>
                    {t('Feedback')}
                </PrimaryButton>}

                {isPaymentActive && <PrimaryButton onClick={onRepeatPurchase}>
                    {t('Buy')}
                </PrimaryButton>}

            </ContentBox>
        </ScreenWrapper>
    );
};



export default ServiceMain;
