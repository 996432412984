import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import ScreenTitle from '../../../common/ScreenTitle';
import PrimaryButton from '../../../common/PrimaryButton';

export const CheckboxBox = styled.div`
display: flex;
width: 60vw;
justify-content: space-between;
margin-top: 20px;
margin-bottom: 10px;
`;
export const FeedbacksButtonBox = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
text-transform: lowercase;
`;

export const CustomCheckbox = styled(Checkbox)`
width: 25vw;
`;

export const AlertTitle = styled(ScreenTitle)`
font-size: 1.2rem;
color: red;
`;

export const FeedbacksButton = styled(PrimaryButton)`
  width: 40vw;
  max-width: 40vw;
`;

export const FormGroupBox = styled(FeedbacksButtonBox)`
display: flex;
justify-content: space-between;
width: 100%;
text-transform: lowercase;
flex-direction: column;
justify-items: center;
align-items: center;
`;


