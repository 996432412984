import { clear } from 'console';
import {
    STORAGE_KEYS,
    STORAGE_RENEW_INTERVAL,
    TAB_SYNC_DURATION,
} from '../constants';
import { VendingMachine } from '../types';
import { cltext, getCurrentTimeStr } from './general';

export const storeVMState = (vm: VendingMachine) => {
    // const data = { ...vm }; // TODO define only needed fields

    localStorage.setItem(STORAGE_KEYS.VM_STATE, JSON.stringify(vm));
    localStorage.setItem(STORAGE_KEYS.VM_SYNC_TIMESTAMP, Date.now().toString());

    // TODO remove this
    localStorage.setItem(
        STORAGE_KEYS.VM_SYNC_TIMESTAMP + ' time',
        getCurrentTimeStr()
    );
};

export const clearSavedVMState = () => {
    localStorage.removeItem(STORAGE_KEYS.VM_STATE);
    localStorage.removeItem(STORAGE_KEYS.VM_SYNC_TIMESTAMP);
    localStorage.removeItem(STORAGE_KEYS.VM_SYNC_TIMESTAMP + ' time');
};

export const clearTabData = () => {
    localStorage.removeItem(STORAGE_KEYS.TAB_ID);
    localStorage.removeItem(STORAGE_KEYS.TAB_START_TIMESTAMP);

    sessionStorage.removeItem(STORAGE_KEYS.TAB_ID);
    sessionStorage.removeItem(STORAGE_KEYS.TAB_START_TIMESTAMP);
};

export const loadSavedVMState = (): any => {
    const storedData: object = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.VM_STATE) ?? '{}'
    );
    const storedTimestamp =
        localStorage.getItem(STORAGE_KEYS.VM_SYNC_TIMESTAMP) ?? '0';

    cltext(' >> storedData', 'greenyellow');
    console.log('storedData', storedData);

    cltext(` >> storedTimestamp: ${storedTimestamp}`, 'greenyellow');
    cltext(
        ` >> expiration counter: ${
            (Date.now() - parseInt(storedTimestamp)) / 1000
        } and allowed: ${TAB_SYNC_DURATION / 1000}`
    );

    const isSavedDataExpired =
        Date.now() - parseInt(storedTimestamp) > TAB_SYNC_DURATION;
    cltext(
        ` >> Is stored state expired: ${
            isSavedDataExpired ? 'expired' : 'still valid'
        }`,
        'greenyellow'
    );

    return {
        ...storedData,
        isSavedDataExpired,
    };
};

export const loadSavedOrderState = (vmConnectionDelay: number = 0): any => {
    const storedData: object = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.ORDER_STATE) ?? '{}'
    );
    const storedTimestamp =
        localStorage.getItem(STORAGE_KEYS.ORDER_SYNC_TIMESTAMP) ?? '0';

    cltext(' >> stored order', 'greenyellow');
    console.log('stored order data', storedData);

    // cltext(` >> stored order Timestamp: ${storedTimestamp}`, 'greenyellow');
    cltext(
        ` >> expiration counter: ${
            (Date.now() - parseInt(storedTimestamp)) / 1000
        } and allowed: ${TAB_SYNC_DURATION / 1000 + vmConnectionDelay} `
    );

    const isSavedDataExpired =
        Date.now() - parseInt(storedTimestamp) >
        TAB_SYNC_DURATION + vmConnectionDelay * 1000;
    cltext(
        ` >> Is stored order state expired: ${
            isSavedDataExpired ? 'expired' : 'still valid'
        }`,
        'greenyellow; font-weight: bold;'
    );

    return {
        ...storedData,
        isSavedDataExpired,
    };
};

export const clearSavedOrderState = () => {
    localStorage.removeItem(STORAGE_KEYS.ORDER_STATE);
    localStorage.removeItem(STORAGE_KEYS.ORDER_SYNC_TIMESTAMP);
};

export const clearAllSavedStates = () => {
    cltext('🗑️ clearing all saved states', 'red');
    clearSavedVMState();
    clearSavedOrderState();
    clearTabData();
};

export const refreshLocalStorage = (version: string) => {
    const storedAppVersion = localStorage.getItem(STORAGE_KEYS.APP_VERS);
    const storedTimestamp =
        localStorage.getItem(STORAGE_KEYS.STORE_TIMESTAMP) ?? '0';
    const isStorageExpired =
        Date.now() - parseInt(storedTimestamp) > STORAGE_RENEW_INTERVAL;

    cltext(
        `💾 [storage utils] \n🔍 storedAppVersion: ${storedAppVersion} vs version: ${version}`,
        'greenyellow'
    );
    if (storedAppVersion !== version || isStorageExpired) {
        cltext(
            '💾 [storage utils] \n🔄 refreshing local storage',
            'greenyellow'
        );
        localStorage.clear();
        sessionStorage.clear();

        localStorage.setItem(
            STORAGE_KEYS.STORE_TIMESTAMP,
            Date.now().toString()
        );
    }
    localStorage.setItem(STORAGE_KEYS.APP_VERS, version);
};
