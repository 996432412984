import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { isDev } from '../../../constants';
import useTimer from '../../../hooks/useTimer';
import { receiptData, vmState } from '../../../state/recoilState';
import { BasicScreenProps, Order, ReceiptData } from '../../../types';
import PrimaryButton from '../../common/PrimaryButton';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';
import style from '../ServiceScreens/ServiceWait.module.css';

const ServiceWait = (props: BasicScreenProps) => {
    const { t } = useTranslation();
    const [vm, setVm] = useRecoilState(vmState);
    const [data, setData] = useRecoilState<ReceiptData>(receiptData);
    const navigate = useNavigate();

    const timerValue = data.evadts_waiting;
    const timerHook = useTimer(timerValue);

    const onMainPage = (): void => {
        navigate(`/${vm.qr}/servicemain`, { replace: true });
    };

    const urlGetFile = isDev
        ? '/dev/get_file.json'
        : `/api/get_file?k=${vm.vm_id}`;

    async function getFile(url: string): Promise<void> {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                console.log("All's good");
            } else {
                navigate(`/${vm.qr}/servicefail`, { replace: true });
            }
        } catch (error) {
            console.error('Request failed:', error);
        }
    }

    const urlGetReceipt = isDev
        ? '/dev/get_fisc_data.json'
        : `/api/get_fisc_data?k=${vm.vm_id}`;

    async function funcReceipt(url: string): Promise<void> {
        try {
            const response = await fetch(url, {
                method: 'POST',
            });
            if (!response.ok) {
                console.warn('Something goes wrong');
                throw new Error(response.statusText);
            }
            const result: ReceiptData = await response.json();
            setData(result);
        } catch (error) {
            console.error('Request failed:', error);
        }
    }

    useEffect(() => {
        getFile(urlGetFile);
        timerHook.start();
        return () => {
            timerHook.reset();
        };
    }, []);

    useEffect(() => {
        const timeNowUTC = new Date().toISOString().substring(0, 19); // transform time to UTC => sec
        const timeNow = Date.parse(timeNowUTC) / 1000; // time now in sec
        const timeReceipt = Date.parse(data.current_fiscal_datetime_UTC) / 1000; //time from DB in sec

        if (timeNow - timeReceipt <= data.last_receipt) {
            navigate(`/${vm.qr}/servicecomplete`, { replace: true });
        } else {
            funcReceipt(urlGetReceipt);
        }
        if (timerHook.time <= 0) {
            navigate(`/${vm.qr}/servicefail`, { replace: true });
            timerHook.reset();
            console.log(timerHook.time);
        }
    }, [timerHook.time]);

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Request wait')} </ScreenTitle>
            <div className={style.box}>
                <object className={style.element} data='/Subtract.png' />
            </div>
            <PrimaryButton onClick={onMainPage}>{t('Main page')}</PrimaryButton>
        </ScreenWrapper>
    );
};

export default ServiceWait;
