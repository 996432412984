import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import {
    DISABLE_OR_ENABLE_BUTTON,
    FEEDBACKS_CUSTOMER_STATUS,
    FEEDBACKS_ESTIMATE,
} from '../../../constants';
import {
    feedbackState,
    pageNumber,
    pageNumberVisibility,
    telemetryEvent,
    updateFeedbackState,
    vmState,
} from '../../../state/recoilState';
import {
    BasicScreenProps,
    Feedback,
    VendingMachine,
    telemetryEventCheck,
    updateFeedback,
} from '../../../types';
import {
    createTelemetryEvent,
    updateFeedbacks,
    updateTelemetryEvent,
} from '../../../utils/general';
import ContentBox from '../../common/ContentBox';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';
import {
    SmileBadColor,
    SmileGoodColor,
} from './components/CheckboxItems/CheckboxSvg';
import {
    CheckboxBox,
    CustomCheckbox,
    FeedbacksButton,
    FeedbacksButtonBox,
} from './components/FeedbacksStyles';
import { SmileTitle } from './components/SmileTitle';

interface SmilePage extends BasicScreenProps {
    onCancel?: () => void;
}

const ButtonsBox = styled(FeedbacksButtonBox)`
    justify-content: center;
`;

const SmilePage = (props: SmilePage) => {
    const [feedback, setFeedback] = useRecoilState<Feedback>(feedbackState);
    const [updatedFeedback, setUpdateFeedback] =
        useRecoilState<updateFeedback>(updateFeedbackState);
    const vm = useRecoilValue<VendingMachine>(vmState);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const setNumberVisibility = useSetRecoilState(pageNumberVisibility);
    const [page, setPageNumber] = useRecoilState<string>(pageNumber);
    const [telemetryCheckID, setTelemetryCheckID] =
        useRecoilState<telemetryEventCheck>(telemetryEvent);

    useEffect(() => {
        setPageNumber('1/3');
        setNumberVisibility(true);
    }, []);

    const handleSmileBadChange = (props: any) => {
        const { id } = props.target;

        const isGoodFeedback = id === 'Good';
        const nextPage = isGoodFeedback ? 'wishespage' : 'checkboxpage';
        const estimate = isGoodFeedback
            ? FEEDBACKS_ESTIMATE.POSITIVE_SMILE
            : FEEDBACKS_ESTIMATE.NEGATIVE_SMILE;

        const newfeedback = {
            ...updatedFeedback,
            chosen_options: null,
            custom_content: null,
            customer_name: null,
            customer_phone_number: null,
            estimate,
            customer_status: FEEDBACKS_CUSTOMER_STATUS.AFTER_SMILE_PAGE,
            id: feedback.id,
        };

        setUpdateFeedback(newfeedback);
        updateFeedbacks(feedback.id, newfeedback);

        const telemetryEstimate = isGoodFeedback
            ? FEEDBACKS_ESTIMATE.POSITIVE_SMILE
            : FEEDBACKS_ESTIMATE.NEGATIVE_SMILE;

        if (telemetryCheckID.id != null) {
            updateTelemetryEvent(
                feedback.id,
                telemetryEstimate,
                telemetryCheckID.id
            );
        } else {
            createTelemetryEvent(
                feedback.id,
                telemetryEstimate,
                setTelemetryCheckID
            );
        }
        console.log('TEL_ID', telemetryCheckID.id);
        navigate(`/${vm.qr}/${nextPage}`);
    };

    const onCancelClick = () => {
        setNumberVisibility(false);
        navigate(`/${vm.qr}/completepage`, { replace: true });
    };

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Rate your visit')}</ScreenTitle>
            <ContentBox>
                <CheckboxBox>
                    <div>
                        <CustomCheckbox
                            id='Bad'
                            icon={<SmileBadColor />}
                            onClick={handleSmileBadChange}
                        />

                        <SmileTitle text={t('Bad')} />
                    </div>
                    <div>
                        <CustomCheckbox
                            id='Good'
                            icon={<SmileGoodColor />}
                            onClick={handleSmileBadChange}
                        />
                        <SmileTitle text={t('Great')} />
                    </div>
                </CheckboxBox>

                <ButtonsBox>
                    <FeedbacksButton
                        onClick={onCancelClick}
                        style={DISABLE_OR_ENABLE_BUTTON[1]}
                    >
                        {t('Cancel')}
                    </FeedbacksButton>
                </ButtonsBox>
            </ContentBox>
        </ScreenWrapper>
    );
};

export default SmilePage;
