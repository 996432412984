import GooglePayButton from '@google-pay/button-react';
import React from 'react';
import { isDev } from '../../../constants';
import { cl } from '../../../utils/general';
import { trackClick } from '../../../utils/tracking';

interface GooglePayBtnProps {
    orderAmount: number;
    currencyCode: string;
    onPaymentCompleted: (responce: any) => void;
    onError: (err?: any) => void;
    innerClassName?: string;
}

const GooglePayBtn = (props: GooglePayBtnProps) => {
    const {
        orderAmount,
        currencyCode,
        innerClassName = '',
        onPaymentCompleted,
        onError,
    } = props;
    const environment = isDev ? 'TEST' : 'PRODUCTION';

    return (
        <div
            onClick={() => {
                trackClick('Google pay button', {
                    amount: orderAmount,
                    currency: currencyCode,
                });
            }}
        >
            <GooglePayButton
                className={`button_text kmg ${innerClassName}`}
                buttonType='plain'
                environment={environment}
                paymentRequest={{
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    allowedPaymentMethods: [
                        {
                            type: 'CARD',
                            parameters: {
                                allowedAuthMethods: [
                                    'PAN_ONLY',
                                    'CRYPTOGRAM_3DS',
                                ],
                                allowedCardNetworks: ['MASTERCARD', 'VISA'],
                            },
                            tokenizationSpecification: {
                                type: 'PAYMENT_GATEWAY',
                                parameters: {
                                    gateway: 'portmonecom',
                                    gatewayMerchantId: import.meta.env
                                        .VITE_PORTMONE_MERCHANT_ID,
                                },
                            },
                        },
                    ],
                    merchantInfo: {
                        merchantId: import.meta.env.VITE_PORTMONE_MERCHANT_ID,
                        merchantName: 'Prostopay LLC', // TODO - extract to const (env?)
                    },
                    transactionInfo: {
                        totalPriceStatus: 'FINAL',
                        totalPriceLabel: 'Total',
                        totalPrice: `${orderAmount}`,
                        currencyCode: currencyCode,
                        countryCode: 'UA',
                    },
                }}
                onLoadPaymentData={(paymentRequest) => {
                    cl('load payment data', paymentRequest); // TODO remove
                    onPaymentCompleted(paymentRequest);
                }}
                onError={(error) => {
                    cl('error', error); // TODO remove
                    onError();
                }}
            />
        </div>
    );
};

export default React.memo(GooglePayBtn);
