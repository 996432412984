import { DEVICE_STATUS, ORDER_STATUS } from '../../../constants';

export const isOrderCompleted = (
    status: number,
    device_status: number | null
) =>
    status === ORDER_STATUS.COMPLETED &&
    (device_status === DEVICE_STATUS.PAID ||
        device_status === DEVICE_STATUS.COMPLETED);

export const isOrderRefunded = (status: number, device_status: number | null) =>
    status === ORDER_STATUS.REFUNDED && device_status === DEVICE_STATUS.PAID;

export const isOrderPaymentFailed = (status: number) =>
    status === ORDER_STATUS.PAYMENT_FAIL;

export const isPaymentSuccessful = (status: number) =>
    status === ORDER_STATUS.COMPLETED;

export const isCT5Paid = (status: number, device_status: number | null) =>
    status === ORDER_STATUS.PREPAID && !device_status;

export const isCT5Connected = (status: number, device_status: number | null) =>
    device_status === DEVICE_STATUS.CONNECTED &&
    status === ORDER_STATUS.PREPAID;

export const isCT5PaymentFailed = (status: number) =>
    status === ORDER_STATUS.PAYMENT_FAIL;

export const isCT5FlowCompleted = (
    status: number,
    device_status: number | null
) => status === ORDER_STATUS.COMPLETED && device_status === DEVICE_STATUS.PAID;

export const isCT5Selected = (status: number, device_status: number | null) =>
    device_status === DEVICE_STATUS.PRODUCT_SELECTED &&
    status === ORDER_STATUS.PREPAID;

export const isCT5Refunded = (status: number, device_status: number | null) => {
    const hasRefundStatus =
        status === ORDER_STATUS.REFUNDED &&
        (device_status === DEVICE_STATUS.PAID ||
            device_status === DEVICE_STATUS.COMPLETED);

    const hasWrongCompletedCombination =
        status === ORDER_STATUS.COMPLETED &&
        device_status === DEVICE_STATUS.COMPLETED;

    return hasRefundStatus || hasWrongCompletedCombination;
};

export const decryptOrderData = (
    status: number,
    device_status: string | number | null
) => {
    return {
        isOrderCompleted: isOrderCompleted(status, device_status as number),
        isOrderRefunded: isOrderRefunded(status, device_status as number),
        isOrderPaymentFailed: isOrderPaymentFailed(status),
        isPaymentSuccessful: isPaymentSuccessful(status),
    };
};

export const decryptCT5Data = (
    status: number,
    device_status: number | null
) => {
    return {
        isCT5Paid: isCT5Paid(status, device_status),
        isCT5Connected: isCT5Connected(status, device_status),
        isCT5PaymentFailed: isCT5PaymentFailed(status),
        isCT5FlowCompleted: isCT5FlowCompleted(status, device_status),
        isCT5Selected: isCT5Selected(status, device_status),
        isCT5Refunded: isCT5Refunded(status, device_status),
    };
};

export const getNextStepCT5 = (
    status: number,
    device_status: string | number | null,
    id: string | null
) => {
    let logMessage = '';
    let navigatePath = '';

    const {
        isCT5Paid,
        isCT5Connected,
        isCT5PaymentFailed,
        isCT5FlowCompleted,
        isCT5Selected,
        isCT5Refunded,
    } = decryptCT5Data(status, device_status as number);

    switch (true) {
        case isCT5Paid && !isCT5Connected: // paid but not connected yet
            logMessage = 'switch - CT5 paid';
            break;
        case isCT5Connected: // paid and connected
            logMessage = 'switch - CT5 connected';
            navigatePath = `/${id}/select`;
            break;
        case isCT5PaymentFailed:
            logMessage = 'switch - payment failed';
            navigatePath = `/${id}/failed`;
            break;
        case isCT5FlowCompleted:
            logMessage = 'switch - flow completed';
            navigatePath = `/${id}/success`;
            break;
        case isCT5Selected:
            logMessage = 'switch - CT5 selected';
            break;
        case isCT5Refunded:
            logMessage = 'switch - CT5 refunded';
            navigatePath = `/${id}/refund`;
            break;
    }

    return { logMessage, navigatePath };
};
