import styled from 'styled-components';
import style from './ApplePayButton.module.css';

import ApplePayIcon from './ApplePayIcon';

const ApplePayButtonText = styled.span`
    font-family: HelveticaNeue-Medium;
    font-size: 17px;
    padding-bottom: 1.7px;
    padding-right: 6px;
    letter-spacing: 0;
`;

const ApplePayButtonInnerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const StyledButton = styled.button`
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

interface ApplePayButtonProps {
    theme?: 'light' | 'dark';
    ariaLabel?: string;
    text?: string;
    onClick?: (e: any) => void;
}

const ApplePayButton = ({
    theme = 'dark',
    ariaLabel = 'Pay with Apple Pay',
    text = 'Pay with',
    ...extraProps
}: ApplePayButtonProps) => {
    const isLightTheme = theme.toLowerCase() === 'light';

    return (
        <StyledButton
            className={`${style.applePayButton} ${style.applePayButtonTheme}${
                isLightTheme ? 'Light' : 'Dark'
            }}`}
            {...extraProps}
            aria-label={ariaLabel}
        >
            <ApplePayButtonInnerWrapper>
                <ApplePayButtonText>{text}</ApplePayButtonText>
                <ApplePayIcon fill={isLightTheme ? 'black' : 'white'} />
            </ApplePayButtonInnerWrapper>
        </StyledButton>
    );
};

export default ApplePayButton;
