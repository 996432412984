import i18n from 'i18next';
import { VendingMachine } from '../types';

import { cl, getVmDetails } from './general';
import { trackEvent } from './tracking';

interface PurchaseData {
    // isAllowed: boolean;
    // isFridge: boolean;
    // isFridgeSmart: boolean;
    // isCT5: boolean;
    // isConnected: boolean;
    // language_code: string | undefined;
    vmData: VendingMachine;
}

interface PurchaseHandlers {
    notSupportedHandler: (qr: string) => void;
    notConnectedHandler: (qr: string) => void;
    successHandler: (vm: VendingMachine) => void;
}

interface PurchaseParams extends PurchaseData, PurchaseHandlers {
    qr: string;
}

export function checkOrder(params: PurchaseParams) {
    const {
        vmData,
        qr,
        notSupportedHandler,
        notConnectedHandler,
        successHandler,
    } = params;

    const {
        isAllowed,
        isFridge,
        isFridgeSmart,
        isCT5,
        isConnected,
        language_code,
    } = getVmDetails(vmData);

    // cltext('checkOrder', 'skyblue');
    if ((!isAllowed && !isFridge) || isFridgeSmart) {
        cl('Not supported VM');
        trackEvent('not-supported', { qr });
        notSupportedHandler(qr);
    } else if (!isConnected && isCT5) {
        cl('Not connected AND CT5');
        trackEvent('not-connected', { qr });
        notConnectedHandler(qr);
    } else {
        if (language_code) {
            i18n.changeLanguage(language_code === 'ge' ? 'ka' : language_code); // on machines Georgian lang marked as 'ge' while in browser it's 'ka'
        }
        successHandler(vmData);
    }
}

// export function checkAllowed({ action, qr, isAllowed, isFridge,isFridgeSmart,isCT5 }: { action: string; qr: string }) {
//     if (isAllowed || isFridge) {
//         checkOrder({
//             isAllowed,
//             isFridge,
//             isFridgeSmart,
//             isCT5,
//             isConnected,
//             language_code,
//             vmData: result,
//             qr,
//             notSupportedHandler: onNotSupported,
//             notConnectedHandler: onNotConnected,
//             successHandler: onSuccess,
//         });
//     } else {
//         switch (action) {
//             case 'notSupported':
//                 onNotSupported(qr);
//                 break;
//             case 'fiscalService':
//                 onFiscalService(qr);
//                 break;
//             case 'mainService':
//                 onMainService(qr);
//                 break;
//             case 'feedbacks':
//                 onFeedbacks(qr);
//                 break;
//         }
//     }
// }
