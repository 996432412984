export function SmileTitle(props: any) {
    const {textColor, text} = props;
return (<div
    style={{
      fontSize: '1.2rem',
      color: '#1b3f92',
      fontWeight: '400',
      paddingBottom: '1rem',
      textAlign: 'center',
    }}>{text}</div>);
}