import { isDev } from './constants';

export const FEEDBACK_SCREENS = {
    CONNECTING: false,
    SELECT_PRODUCT: false,
    SNACK_SCREEN: false,
    WATER_SCREEN: false,
    NOT_SUPPORTED: false,
    NOT_CONNECTED: false,
    PAYMENT: false,
    PAYMENT_CT5: false,
    PAYMENT_CT10: false,
    FAILURE: false,
    REFUND: false,
    WAIT: false,
};

export const TIMER_START_VALUE = isDev ? 50 : 90;
export const WAIT_TIMER = isDev ? 40 : 120;
export const DEV_TIMER_DELAY = 3;

export const LIMITS = {
    SNACK: { MIN: 0.1, MAX: 500 },
    WATER: { MIN: 1, MAX: 100 },
    PAYMENT_CT5: { MIN: 0.01, MAX: 500 },
    PAYMENT_CT10: { MIN: 0.01, MAX: 500 },
};

export const FISCAL_SET = {
    SUCCESS: 200,
    TIMEOUT: -3,
    FISCALISATED: 1,
};
