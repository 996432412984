import { useTranslation } from 'react-i18next';
import { BasicScreenProps, VendingMachine } from '../../../types';
import PrimaryButton from '../../common/PrimaryButton';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';
import { useRecoilValue } from 'recoil';
import { vmState } from '../../../state/recoilState';
import { MACHINE_TYPES } from '../../../constants';

interface CompletePage extends BasicScreenProps {
  onRepeatPurchase?: () => void;
}

const CompletePage = (props: CompletePage) => {
  const { onRepeatPurchase } = props;
  const { t } = useTranslation();
  const vm = useRecoilValue<VendingMachine>(vmState);
  const isFridge = vm.type_id === MACHINE_TYPES.FRIDGE;

  return (
    <ScreenWrapper>
      <ScreenWrapper>
        <ScreenTitle>{t('Thank for answers')} </ScreenTitle>
        <object data="/tick.html" />
        {(vm.allowed || isFridge) &&
          <PrimaryButton onClick={onRepeatPurchase}>
          {t('Repeat_purchase')}
        </PrimaryButton>}
      </ScreenWrapper>
    </ScreenWrapper>
  );
};

export default CompletePage;