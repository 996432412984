import { API, isDev } from '../constants';
import { cl, cltext } from './general';

export const postGooglePayment = async (
    orderId: string | undefined,
    paymentRequest: Record<string, unknown>,
    onOrderSave?: (data: any) => void
): Promise<void> => {
    // cl('postGooglePayment', paymentRequest);
    // cl(JSON.stringify(paymentRequest));
    const url = `${API.ORDER_GOOGLE_PAYMENT}?id=${orderId}`;
    try {
        const response = await fetch(url, {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentRequest),
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        const result = await response.json();
        onOrderSave && onOrderSave(result);
    } catch (error) {
        // onError();
        cl('postGooglePayment error', error);
    }
};

type SetSuccessFetchFunction = (value: boolean, amount?: string) => void;

export const setOrderAmount = (
    orderId: string,
    amount: number,
    setSuccessFetch: SetSuccessFetchFunction
): void => {
    cl('setOrderAmount', orderId, amount);
    setSuccessFetch(false);
    cltext(`setOrderAmount URL: ${API.ORDER_AMOUNT}?id=${orderId}`, 'skyblue');
    fetch(`${API.ORDER_AMOUNT}?id=${orderId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount }),
    })
        .then((response) => {
            cltext(
                `order amount validated. Status: ${response.status}`,
                'green'
            );
            return response.json();
        })
        .then((data) => {
            cltext('setOrderAmount data', 'magenta');
            cl('setOrderAmount data', data);
            if (data.amount == amount) {
                setSuccessFetch(true, 'max');
            }
        })
        .catch((error) => {
            cl('setOrderAmount error', error);
        });
};

export const validateAppleMerchant = (validationURL: string) => {
    return fetch(
        `${API.VALIDATE_APPLE_MERCHANT}?url=${encodeURIComponent(
            validationURL
        )}`
    ).then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
    });
};
