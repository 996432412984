import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { FISCAL_SET } from './screensSettings';
import {
    feedbackState,
    nfcState,
    orderState,
    qrState,
    // receiptData,
    vmState,
} from './state/recoilState';

import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { Feedback } from './types';
import {
    checkCurrentTabUniqueness,
    cl,
    clearStoredTabData,
    cltext,
    getUrlParams,
    initUniqueTabData,
} from './utils/general';
import { trackClick, trackEvent } from './utils/tracking';

import './App.css';
import { API, FEEDBACK_SRC, STORAGE_KEYS, isDev } from './constants';
import AppRouter from './router/AppRouter';
import {
    clearAllSavedStates,
    clearSavedOrderState,
    refreshLocalStorage,
} from './utils/stateStorage';

console.log('App v.', APP_VERSION);
refreshLocalStorage(APP_VERSION);

// 0. init tab data
initUniqueTabData();
const { tabId, tabTimestamp } = checkCurrentTabUniqueness();
// const existingLStimestamp = localStorage.getItem(
//     STORAGE_KEYS.TAB_START_TIMESTAMP
// );

// 1. if new tab opened after TAB_SYNC_DURATION - no data sync
// 2. if new tab opened during TAB_SYNC_DURATION - sync data in the direction from the oldest to newest
// 3. if new tab opened after TAB_SYNC_DURATION - no data sync
// 4. if old tab already synced - navigate it to expired screen ?
// 5. if new tab opened during TAB_SYNC_DURATION - prevent sending new requests to the server - restore data from old one, if possible. How to do it? Validate state?

// on windows close remove tabId from local storage
window.addEventListener('beforeunload', () => {
    // cltext('beforeunload' + tabId);
    clearStoredTabData(tabId);
});

function App() {
    // const [urlQrCode, setUrlQrCode] = useState<string>('');
    // const [urlNfc, setUrlNfc] = useState<string>('');
    const [qrCode, setQrCode] = useRecoilState<string>(qrState);
    const [urlService = false, setUrlService] = useState<string>('');

    const setNfcState = useSetRecoilState(nfcState);
    const [vm, setVm] = useRecoilState(vmState);
    const [order, setOrder] = useRecoilState(orderState);
    // const [error, setError] = useState<string>('');
    const navigate = useNavigate();
    const showReceiptGlobal = vm?.fisc_receipts === 1 || false;
    const showFeedbackGlobal = vm?.feedbacks === 1 || false;
    const showCashlessReceipt =
        order?.fiscal_req === FISCAL_SET.SUCCESS ||
        (order?.fiscal_req === FISCAL_SET.FISCALISATED &&
            order?.fiscal_success_ID != FISCAL_SET.TIMEOUT);
    const [feedback, setFeedback] = useRecoilState<Feedback>(feedbackState);

    const onStorageUpdate = (e: StorageEvent) => {
        // cltext('storage event key: ' + e.key);

        if (e.key === STORAGE_KEYS.TAB_ID) {
            // cltext('storage event newValue: ' + e.newValue);
            // cltext('storage event oldValue: ' + e.oldValue);

            if (e.oldValue === tabId) {
                clearAllSavedStates();
                navigate('/session-expired', { replace: true });
            }
        }
    };

    useEffect(() => {
        const { qr, nfc, service } = getUrlParams();

        if (qr) {
            cltext('qr from url: ' + qr, 'yellow');
            // setUrlQrCode(qr);
            setQrCode(qr);
        }
        if (nfc) {
            setNfcState(Boolean(nfc));
        }
        if (service) {
            setUrlService(service);
        }

        window.addEventListener('storage', onStorageUpdate);

        return () => {
            window.removeEventListener('storage', onStorageUpdate);
        };
    }, []);

    // TODO extract to utils after refactoring
    // general error handler
    // we can use it to log errors in the future
    const onError = (error: any, page = ''): void => {
        cltext(`### Error: ${error} on page: ${page}`);

        Sentry.captureException(
            `83: [${page}] (${qrCode} - ${vm.qr}) - ${error}`
        );

        let errorUrlPrefix = `/${qrCode ?? vm.qr}`;
        if (!qrCode && (!vm.qr || vm.qr === 'qr')) {
            errorUrlPrefix = `/${page != '' ? page : 'app'}`;
        }
        clearSavedOrderState();
        navigate(`${errorUrlPrefix}/error`, { replace: true });
    };

    const cancelOrder = async (orderId: string): Promise<void> => {
        try {
            const response = await fetch(`${API.CANCEL}?id=${orderId}`);
            if (!response.ok) {
                throw new Error(
                    `HTTP error! status: ${response.status}; statusText: ${response.statusText}`
                );
            }
            // const data = await response.json();
            // cl('cancelOrder', data);
        } catch (error) {
            cl('cancelOrder error', error);
            Sentry.captureException(`cancelOrder error - 103: ${error}`);
        }
    };

    const onCancelClickHandler = (page = ''): void => {
        trackClick(`cancel - ${page}`);
        cl('cancel click');
        if (order?.id) {
            cancelOrder(order?.id);
        }
        clearAllSavedStates();
        navigate(`/${order?.id}/canceled`, { replace: true });
    };

    const onTimerTimeoutHandler = (page = '', shouldRedirect = true): void => {
        trackClick(`timer timeout - ${page}`, {
            orderId: order?.id,
            userId: order?.user_id,
        });
        cl('timer timeout');
        if (order?.id) {
            cancelOrder(order?.id);
        }
        if (shouldRedirect) {
            clearAllSavedStates();
            navigate(`/${order?.id ?? vm.qr}/canceled`, { replace: true });
        }
    };

    const onGetReceiptHandler = (): void => {
        trackClick('get receipt');
        cl('get receipt click', order?.receipt_key);
        if (order?.receipt_key) {
            window.open(`${API.RECEIPT}?k=${order?.receipt_key}`, '_blank');
        }
    };

    const onRepeatPurchaseClickHandler = (page = ''): void => {
        trackClick(`repeat purchase - ${page}`, { qrCode });
        // TODO  - validate scenario when qrCode is not defined
        // commented to decrease number of errors in sentry
        // console.log('repeat purchase click', qrCode, urlQrCode);
        // if (!qrCode) {
        //     Sentry.captureException(
        //         `qr code is not defined in repeat purchase. qrCode: ${qrCode}, urlQrCode: ${urlQrCode}, page: ${page}`
        //     );
        // }
        clearSavedOrderState();
        const qrParam = qrCode ? `?qr=${qrCode}` : '';
        cltext(`repeat purchase click - on page: ${page}\nqr: ${qrParam}`);
        window.location.href = `/${qrParam}`;
    };

    const onFeedbackClickHandler = (): void => {
        const service = window.location.pathname.includes('servicemain');
        if (order?.id) {
            const isCT5 = vm.ct_type == 5;
            !isCT5 && fetch(`${API.CANCEL}?id=${order.id}`);
        }
        createFeedback(
            vm.vm_id,
            service ? FEEDBACK_SRC.SERVICE : FEEDBACK_SRC.PAYMENT
        );
        trackEvent('Feedbacks button click', {
            orderId: order?.id,
        });
        navigate(`/${qrCode}/smilepage`, { replace: true });
    };

    //-------------------Create feedbacks API call --------------------------//
    async function createFeedback(vm: number, src: number): Promise<void> {
        const url = isDev
            ? '/dev/feedback_mock.json'
            : `${API.FEEDBACK_DATA}?vm=${vm}`;
        const orderInfo = {
            wallet_user_id: order.user_id,
            transaction_id: order.id,
            src: src,
        };
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(orderInfo),
            });
            if (response.status === 200) {
                const result: Feedback = await response.json();
                setFeedback(result);
            } else {
                console.warn('Something goes wrong');
                throw new Error(response.statusText);
            }
        } catch (error) {
            console.error('Request failed:', error);
        }
    }
    //-------------------Create feedbacks API call --------------------------//

    const onReceiptCapcha = (): void => {
        const isCT5 = vm.ct_type == 5;
        if (order?.id) {
            !isCT5 && fetch(`${API.CANCEL}?id=${order.id}`);
        }
        navigate(`/${qrCode}/servicerobot`, { replace: true });
        // + navigate to robo capcha page
    };
    // GetReceipt button on Completed page has a different logic than Receipt button on
    // Connected, Select, Payment5, Payment10, Water, Snack. So there are different
    // function in props

    return (
        <div className='App'>
            <AppRouter
                vm={vm}
                order={order}
                showFeedbackGlobal={showFeedbackGlobal}
                showReceiptGlobal={showReceiptGlobal}
                showCashlessReceipt={showCashlessReceipt}
                onFeedbackClickHandler={onFeedbackClickHandler}
                onCancelClickHandler={onCancelClickHandler}
                onTimerTimeoutHandler={onTimerTimeoutHandler}
                onRepeatPurchaseClickHandler={onRepeatPurchaseClickHandler}
                onGetReceiptHandler={onGetReceiptHandler}
                onReceiptCapcha={onReceiptCapcha}
                onError={onError}
            />
        </div>
    );
}

export default Sentry.withProfiler(App);
