import * as Sentry from '@sentry/react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from '../components/Layout';
import {
    CompletePage,
    CompletedScreen,
    Connecting,
    Error404Screen,
    FailureScreen,
    NotConnectedScreen,
    NotSupportedScreen,
    PaymentCT10Screen,
    PaymentCT5Screen,
    PaymentScreen,
    RefundScreen,
    ScanQrCode,
    SelectProduct,
    SnackScreen,
    WaitScreen,
    WaterScreen,
} from '../components/screens';
import CheckboxPage from '../components/screens/FeedbacksScreen/CheckboxPage';
import ContactPage from '../components/screens/FeedbacksScreen/ContactPage';
import SmilePage from '../components/screens/FeedbacksScreen/SmilePage';
import WishesPage from '../components/screens/FeedbacksScreen/WishesPage';
import OldTabScreen from '../components/screens/OldTabScreen/OldTabScreen';
import ServiceComplete from '../components/screens/ServiceScreens/ServiceComplete';
import ServiceFail from '../components/screens/ServiceScreens/ServiceFail';
import ServiceMain from '../components/screens/ServiceScreens/ServiceMain';
import ServiceRobot from '../components/screens/ServiceScreens/ServiceRobot';
import ServiceWait from '../components/screens/ServiceScreens/ServiceWait';
import { FEEDBACK_SCREENS } from '../screensSettings';

interface AppRouterProps {
    vm: any;
    order: any;
    showFeedbackGlobal: boolean;
    showReceiptGlobal: boolean;
    showCashlessReceipt: boolean;
    onFeedbackClickHandler: () => void;
    onCancelClickHandler: (screen?: string) => void;
    onTimerTimeoutHandler: (screen: string, shouldRedirect?: boolean) => void;
    onRepeatPurchaseClickHandler: (screen?: string) => void;
    onGetReceiptHandler: () => void;
    onReceiptCapcha: () => void;
    onError: (e: any, screen: string) => void;
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRouter = (props: AppRouterProps) => {
    const {
        vm,
        order,
        showFeedbackGlobal,
        showReceiptGlobal,
        showCashlessReceipt,
        onFeedbackClickHandler,
        onCancelClickHandler,
        onTimerTimeoutHandler,
        onRepeatPurchaseClickHandler,
        onGetReceiptHandler,
        onReceiptCapcha,
        onError,
    } = props;

    return (
        <SentryRoutes>
            <Route path='/' element={<Layout />}>
                <Route index element={<ScanQrCode />} />
                <Route
                    path=':id/connecting'
                    element={
                        <Connecting
                            showFeedback={showFeedbackGlobal}
                            onCancel={() => onCancelClickHandler('connecting')}
                            onFeedback={onFeedbackClickHandler}
                            onError={(e: any) => onError(e, 'connecting')}
                            onTimerTimeoutHandler={() =>
                                onTimerTimeoutHandler('connecting')
                            }
                            showReceipt={showReceiptGlobal}
                            onGetReceipt={onReceiptCapcha}
                        />
                    }
                />
                <Route
                    path=':id/select'
                    element={
                        <SelectProduct
                            showFeedback={showFeedbackGlobal}
                            onFeedback={onFeedbackClickHandler}
                            onCancel={() => onCancelClickHandler('select')}
                            onTimerTimeoutHandler={() =>
                                onTimerTimeoutHandler('select')
                            }
                            showReceipt={showReceiptGlobal}
                            onGetReceipt={onReceiptCapcha}
                        />
                    }
                />
                <Route
                    path=':id/snack'
                    element={
                        <SnackScreen
                            showFeedback={showFeedbackGlobal}
                            onFeedback={onFeedbackClickHandler}
                            onCancel={() => onCancelClickHandler('snack')}
                            showReceipt={showReceiptGlobal}
                            onGetReceipt={onReceiptCapcha}
                            onTimerTimeoutHandler={() =>
                                onTimerTimeoutHandler('snack')
                            }
                        />
                    }
                />
                <Route
                    path=':id/water'
                    element={
                        <WaterScreen
                            showFeedback={showFeedbackGlobal}
                            onFeedback={onFeedbackClickHandler}
                            onCancel={() => onCancelClickHandler('water')}
                            showReceipt={showReceiptGlobal}
                            onGetReceipt={onReceiptCapcha}
                            onTimerTimeoutHandler={() =>
                                onTimerTimeoutHandler('water')
                            }
                        />
                    }
                />
                <Route
                    path=':id/payment'
                    element={
                        <PaymentScreen
                            vm={vm}
                            order={order}
                            showFeedback={showFeedbackGlobal}
                            onFeedback={onFeedbackClickHandler}
                            onCancel={() => onCancelClickHandler('payment')}
                            onTimerTimeoutHandler={() =>
                                onTimerTimeoutHandler('payment')
                            }
                        />
                    }
                />
                <Route
                    path=':id/payment5'
                    element={
                        <PaymentCT5Screen
                            showFeedback={showFeedbackGlobal}
                            onFeedback={onFeedbackClickHandler}
                            onCancel={() => onCancelClickHandler('payment ct5')}
                            showReceipt={showReceiptGlobal}
                            onGetReceipt={onReceiptCapcha}
                            onTimerTimeoutHandler={() =>
                                onTimerTimeoutHandler('payment ct5')
                            }
                        />
                    }
                />
                <Route
                    path=':id/payment10'
                    element={
                        <PaymentCT10Screen
                            showFeedback={showFeedbackGlobal}
                            onFeedback={onFeedbackClickHandler}
                            onCancel={() =>
                                onCancelClickHandler('payment ct10')
                            }
                            showReceipt={showReceiptGlobal}
                            onGetReceipt={onReceiptCapcha}
                        />
                    }
                />
                <Route
                    path=':id/wait'
                    element={
                        <WaitScreen
                            showFeedback={showFeedbackGlobal}
                            onFeedback={onFeedbackClickHandler}
                            onTimerTimeoutHandler={() =>
                                onTimerTimeoutHandler('wait', false)
                            }
                        />
                    }
                />
                <Route
                    path=':id/success'
                    element={
                        <CompletedScreen
                            onRepeatPurchase={() =>
                                onRepeatPurchaseClickHandler('success')
                            }
                            showFeedback={showFeedbackGlobal}
                            onFeedback={onFeedbackClickHandler}
                            onGetReceipt={onGetReceiptHandler}
                            showReceipt={showCashlessReceipt}
                        />
                    }
                />
                <Route
                    path=':id/failed'
                    element={
                        <FailureScreen
                            onRepeatPurchase={() =>
                                onRepeatPurchaseClickHandler('failed')
                            }
                            showFeedback={showFeedbackGlobal}
                            onFeedback={onFeedbackClickHandler}
                        />
                    }
                />
                <Route
                    path=':id/canceled'
                    element={
                        <FailureScreen
                            title='Purchase Canceled'
                            onRepeatPurchase={() =>
                                onRepeatPurchaseClickHandler('canceled')
                            }
                            showFeedback={showFeedbackGlobal}
                            onFeedback={onFeedbackClickHandler}
                        />
                    }
                />
                <Route
                    path=':id/error'
                    element={
                        <FailureScreen
                            title='Some error occurred'
                            onRepeatPurchase={() =>
                                onRepeatPurchaseClickHandler('error page')
                            }
                            showFeedback={showFeedbackGlobal}
                            onFeedback={onFeedbackClickHandler}
                        />
                    }
                />
                <Route
                    path=':id/refund'
                    element={
                        <RefundScreen
                            showFeedback={showFeedbackGlobal}
                            onFeedback={onFeedbackClickHandler}
                            onRepeatPurchase={() =>
                                onRepeatPurchaseClickHandler('refund')
                            }
                        />
                    }
                />
                <Route
                    path=':id/notsupported'
                    element={
                        <NotSupportedScreen
                            showFeedback={FEEDBACK_SCREENS.NOT_SUPPORTED}
                            onFeedback={onFeedbackClickHandler}
                        />
                    }
                />
                <Route
                    path=':id/notconnected'
                    element={
                        <NotConnectedScreen
                            onRepeatPurchase={() => {
                                onRepeatPurchaseClickHandler('not connected');
                            }}
                            showFeedback={showFeedbackGlobal}
                            onFeedback={onFeedbackClickHandler}
                        />
                    }
                />
                <Route
                    path=':id/servicemain'
                    element={
                        <ServiceMain
                            onFeedback={onFeedbackClickHandler}
                            onGetReceipt={onReceiptCapcha}
                            onRepeatPurchase={onRepeatPurchaseClickHandler}
                        />
                    }
                />
                <Route
                    path=':id/servicecomplete'
                    element={<ServiceComplete />}
                />
                <Route path=':id/servicefail' element={<ServiceFail />} />
                <Route path=':id/servicerobot' element={<ServiceRobot />} />
                <Route path=':id/servicewait' element={<ServiceWait />} />
                <Route
                    path=':id/smilepage'
                    element={
                        <SmilePage
                            onCancel={() => onCancelClickHandler('smilepage')}
                        />
                    }
                />
                <Route
                    path=':id/checkboxpage'
                    element={
                        <CheckboxPage
                            onCancel={() => onCancelClickHandler('checkboxes')}
                        />
                    }
                />
                <Route
                    path=':id/wishespage'
                    element={
                        <WishesPage
                            onCancel={() => onCancelClickHandler('wishPage')}
                        />
                    }
                />
                <Route
                    path=':id/contactpage'
                    element={
                        <ContactPage
                            onCancel={() =>
                                onCancelClickHandler('feedbacks contact')
                            }
                        />
                    }
                />
                <Route
                    path=':id/completepage'
                    element={
                        <CompletePage
                            onRepeatPurchase={onRepeatPurchaseClickHandler}
                        />
                    }
                />
                <Route
                    path='session-expired'
                    element={<OldTabScreen title='Session timed out' />}
                />
                <Route path='*' element={<Error404Screen />} />
            </Route>
        </SentryRoutes>
    );
};

export default AppRouter;
