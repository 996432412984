import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BasicScreenProps } from '../../../types';

import { useEffect } from 'react';
import { screenLog } from '../../../utils/general';
import { trackPageView } from '../../../utils/tracking';
import ContentBox from '../../common/ContentBox';
import PrimaryButton from '../../common/PrimaryButton';
import SVGObject from '../../common/SVGObject';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';

type NotSupportedScreenProps = BasicScreenProps;

const Message = styled.div`
    font-size: 1.2rem;
    font-weight: 400;
    color: #5c81ed;
    text-align: center;
`;

const Wrapper = styled.div`
    overflow: hidden;
    width: 90%;
    height: 20vh;
    text-align: center;
    display: flex;
    justify-content: center;
`;

const NotSupportedScreen = (props: NotSupportedScreenProps) => {
    const { showFeedback, onFeedback, onCancel } = props;
    const { t } = useTranslation();

    useEffect(() => {
        trackPageView('not supported');
        screenLog('NotSupported Screen');
    }, []);

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Payment not supported')}</ScreenTitle>
            <ContentBox>
                <Wrapper>
                    <SVGObject
                        src='/quark.html'
                        style={{
                            width: '200vw',
                            maxWidth: '700px',
                            height: '20vh',
                        }}
                    />
                </Wrapper>
                <Message>{t('Payment not supported.description_1')}</Message>

                {showFeedback && (
                    <PrimaryButton type='button' onClick={onFeedback}>
                        {t('Left feedback')}
                    </PrimaryButton>
                )}
            </ContentBox>
        </ScreenWrapper>
    );
};

export default NotSupportedScreen;
