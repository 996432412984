import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { QR_SERVICES } from '../../constants';
import { vmState } from '../../state/recoilState';
import { VendingMachine } from '../../types';
import { getVmDetails } from '../../utils/general';
import ButtonBox from '../common/ButtonBox';
import PrimaryButton from '../common/PrimaryButton';
import { Button, SmallButton } from './Button';

interface FeedbackProps {
    onFeedbackClick?: () => void;
    showFeedback?: boolean;
    onReceiptClick?: () => void;
    showReceipt?: boolean;
    onCancelClick?: () => void;
}

const FeedbackAndReceipt = (props: FeedbackProps) => {
    const {
        showFeedback,
        onFeedbackClick,
        showReceipt,
        onReceiptClick,
        onCancelClick,
    } = props;
    const { t } = useTranslation();
    const vm = useRecoilValue<VendingMachine>(vmState);
    const [isReceiptActive, setIsReceiptActive] = useState<boolean>(
        Boolean(showReceipt)
    );
    const [isFeedbacksActive, setIsFeedbacksActive] = useState<boolean>(
        Boolean(showFeedback)
    );
    const { isCT5, isAllowed } = getVmDetails(vm);

    useEffect(() => {
        if (vm.QRservices === QR_SERVICES.SEPARATED_SERVICES && isAllowed) {
            setIsFeedbacksActive(false);
            setIsReceiptActive(false);
        }
    }, []);
  return (
    <ButtonBox>
      {isFeedbacksActive && (
        <PrimaryButton type="button" onClick={onFeedbackClick}>
          {t("Feedback")}
        </PrimaryButton>
      )}

      {isReceiptActive && !isFeedbacksActive ? (
        <Button onClick={onReceiptClick}>{t("Get fiscal receipt")}</Button>
      ) : (
        isReceiptActive && (
          !isCT5 
          ? <SmallButton onClick={onReceiptClick} style={{marginRight:'2px'}}>
            {t("Get fiscal receipt")}
            </SmallButton> 
          : <Button onClick={onReceiptClick}>{t("Get fiscal receipt")}</Button>

        )
      )}

      {isReceiptActive && isFeedbacksActive ? (
        !isCT5 && <SmallButton onClick={onCancelClick} style={{marginLeft:'2px'}}>{t("Cancel")}</SmallButton>
      ) : (
        !isCT5 && <Button onClick={onCancelClick}>{t("Cancel")}</Button>
      )}
    </ButtonBox>
  );
};
export default FeedbackAndReceipt;
