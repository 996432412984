import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { vmState } from '../../../state/recoilState';
import { BasicScreenProps } from '../../../types';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';
import PrimaryButton from '../../common/PrimaryButton';


const ServiceFail = (props: BasicScreenProps) => {
   

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [vm, setVm] = useRecoilState(vmState);

    const onRepeat = (): void => {
        navigate(`/${vm.qr}/servicerobot`, { replace: true });
    };
    const onMainPage = (): void => {
        navigate(`/${vm.qr}/servicemain`, { replace: true });
    };



    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Fail check')} </ScreenTitle>

                <object data='/cross.html' />
                <PrimaryButton onClick={onRepeat}>
                    {t('Try')}
                </PrimaryButton>
                <PrimaryButton onClick={onMainPage}>
                    {t('Main page')}
                </PrimaryButton>
        
        </ScreenWrapper>
    );
};

export default ServiceFail;
