import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
    API,
    DEVICE_STATUS,
    MACHINE_PATHS,
    STORAGE_KEYS,
    isDev,
} from '../../../constants';
import useTimer from '../../../hooks/useTimer';
import {
    nfcState,
    orderState,
    qrState,
    vmState,
} from '../../../state/recoilState';
import { Order, ScreenWithTimerProps, VendingMachine } from '../../../types';
import {
    cl,
    cltext,
    fetchData,
    getVmDetails,
    screenLog,
} from '../../../utils/general';

import ContentBox from '../../common/ContentBox';
import FeedbackAndReceipt from '../../common/FeedbackAndReceipt';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';

import { getConnectionScreenDevUrl } from '../../../utils/dev';
import { trackEvent, trackPageView } from '../../../utils/tracking';
import SVGObject from '../../common/SVGObject';

import {
    clearSavedOrderState,
    loadSavedOrderState,
} from '../../../utils/stateStorage';
import style from './Connecting.module.css';

type ConnectingScreenProps = ScreenWithTimerProps;

const storeOrderState = (order: Order) => {
    localStorage.setItem(STORAGE_KEYS.ORDER_STATE, JSON.stringify(order));
    localStorage.setItem(
        STORAGE_KEYS.ORDER_SYNC_TIMESTAMP,
        Date.now().toString()
    );
};

const Connecting = (props: ConnectingScreenProps) => {
    const {
        showFeedback,
        onCancel,
        onFeedback,
        onError,
        showReceipt,
        onGetReceipt,
        onTimerTimeoutHandler,
    } = props;
    const { t } = useTranslation();
    const timerHook = useTimer();

    const vm = useRecoilValue<VendingMachine>(vmState);
    const nfc = useRecoilValue<boolean>(nfcState);
    const [order, setOrder] = useRecoilState<Order>(orderState);
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [orderId, setOrderId] = useState<string>('');
    const [qrCode] = useRecoilState<string>(qrState);
    const navigate = useNavigate();

    const { isCT10, isCT5, isConnected: hasConnection } = getVmDetails(vm);

    const connectToVM = (onFailure: any) => {
        cltext('Check saved order state ');
        cltext(`VM: ${vm.vm_id} Order: ${orderId} QR: ${qrCode}`);
        cl(loadSavedOrderState(vm.timeout));
        const savedOrder = loadSavedOrderState(vm.timeout);

        if (
            false &&
            savedOrder &&
            savedOrder?.id &&
            !savedOrder.isSavedDataExpired
        ) {
            cltext('🛜 [connecting] Saved order found and loaded', 'skyblue');
            setOrder(savedOrder);
            setOrderId(savedOrder.id as string);
            setIsConnected(true);
            return;
        }
        if (false && savedOrder.isSavedDataExpired) {
            cltext(
                '🛜 [connecting] 🚽 Saved order is expired - delete it',
                'red'
            );
            clearSavedOrderState();
        }

        cl('Connect to VM. QR & VM:', qrCode, vm);
        if (!qrCode) {
            cltext('☠️ QR code is not set', 'red');
            onFailure('QR code is not set');
            return;
        }
        if (!vm || !vm.qr || vm.qr === 'qr') {
            cltext('☠️ VM is not set', 'red');
            onFailure('VM is not set');
            return;
        }

        const url = isDev
            ? getConnectionScreenDevUrl(timerHook.time)
            : `${API.CONNECT}?qr=${vm.qr}${nfc ? '&src=nfc' : ''}`;

        cltext(`🚧 Connect to VM url - ${url}`, 'orange');

        async function connectToVMFetch(url: string): Promise<void> {
            cltext('Connect to VM', 'gray');
            try {
                const response = await fetch(url);

                if (!response.ok) {
                    cl(
                        'Connect to VM failed:',
                        response.status,
                        response.statusText,
                        response.ok
                    );
                    throw new Error(
                        `status ${response.status}, text: ${response.statusText}`
                    );
                }
                const result: Order = await response.json();

                setOrderId(result.id as string);
                setOrder(result);

                // no need to really connect to vm for CT10 and CT5
                if (isCT10 || (isCT5 && hasConnection)) {
                    setIsConnected(true);
                    return;
                }
            } catch (error) {
                cltext('Connect to VM failed');
                cl('Connect to VM failed:', error);
                setIsConnected(false);
                onFailure(error);
            }
        }

        if (!isConnected) {
            connectToVMFetch(url);
        }
    };

    const pingOrderDetails = () => {
        cl(`kmg - checkConnection (${orderId}):`, isConnected);

        if (!orderId || orderId === '') {
            cltext('Order ID is not set');
            return;
        }

        const orderInfoUrl = isDev
            ? getConnectionScreenDevUrl(timerHook.time, true)
            : `${API.ORDER}?id=${orderId}`;

        cltext(`Ping order details - ${orderInfoUrl}`, 'gray');

        try {
            fetchData(
                orderInfoUrl,
                (orderInfo: Order) => {
                    setOrder(orderInfo);

                    const isVmOnline =
                        orderInfo.device_status === DEVICE_STATUS.CONNECTED;

                    if (isCT10 || isVmOnline) {
                        // CT10 - no need to really connect so we can skip this step
                        setIsConnected(true);
                    }
                },
                () => {
                    cltext('Error loading order details (ping) info', 'red');
                    onError &&
                        onError(
                            'Error loading connection info - 122 : ' +
                                orderInfoUrl
                        );
                }
            );
        } catch (e: any) {
            // Sentry.captureException(e);
            onError &&
                onError(
                    'Error loading connection info - 126 : ' +
                        orderInfoUrl +
                        ' :: ' +
                        e.message || ''
                );
        }
    };

    // Logic
    // 1. set timer to 90 seconds
    // 2. start timer
    // 3. if it is CT10 - short delay and redirect to payment10
    // 4. if CT1 - try to connect to vm
    // 5. if connection is successful - reset timer, hide timer, go to next screen
    // 6. if connection is not successful - go to home screen
    // 7. if timer is 0 - go to home screen

    const handleNavigation = (orderData: Order) => {
        let path = MACHINE_PATHS[vm.type_id] || 'select';

        if (isCT5) {
            path = 'payment5';
        }

        if (!orderData.id) {
            cltext('No order ID in navigation link', 'red');
            navigate('/order/error', { replace: true });
        }

        navigate(`/${orderData.id}/${path}`, { replace: true });
    };

    useEffect(() => {
        screenLog('Connecting screen');

        trackPageView('Connecting', { qrCode, orderId });
        timerHook.start();

        cl(`try to connect to vm [${vm.vm_id}]`);
        connectToVM(onError);

        return () => {
            timerHook.reset();
        };
    }, []);

    useEffect(() => {
        if (timerHook.time <= 0) {
            // setQrCode('');
            cl(' timer end - redirect to home');
            onTimerTimeoutHandler && onTimerTimeoutHandler();
        } else {
            // still not connected
            if (!isConnected) {
                pingOrderDetails();
            }
        }
    }, [timerHook.time]);

    useEffect(() => {
        if (isConnected) {
            trackEvent('Connected', {
                orderId,
                qr: qrCode,
                userId: order?.user_id,
            });
            timerHook.reset();

            storeOrderState(order);

            cltext(`Oder ${orderId} is created`);
            cl('order', order);
            handleNavigation(order);
        }
    }, [order, isConnected]);

    return (
        <ScreenWrapper>
            <ScreenTitle>
                {t('Connecting to vm')} {vm?.name} {vm?.vmNumber}
            </ScreenTitle>
            <ContentBox>
                <SVGObject className={style.vm} src='/vm.html' />

                <FeedbackAndReceipt
                    showReceipt={showReceipt}
                    showFeedback={showFeedback}
                    onCancelClick={onCancel}
                    onReceiptClick={onGetReceipt}
                    onFeedbackClick={onFeedback}
                />
            </ContentBox>
        </ScreenWrapper>
    );
};

export default Connecting;
