import styled from 'styled-components';

const InputWrapper = styled.div`
  margin: 1vh 0 0 0;
  // font-size: min(3.9vw, 26px);
  // font-weight: medium;
  // color: #5c81ed;
  text-align: center;
`;

export default InputWrapper;
