import styled from 'styled-components';

const ScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 70vh;
  font-family: "Commissioner" "Franklin Gothic Medium", "Arial Narrow", Arial,
    sans-serif;
`;

export default ScreenWrapper;
