import * as Sentry from '@sentry/browser';
import { API, QR_SERVICES, isDev, isStage } from '../../../constants';
import { DEV_MACHINES } from '../../../settings';
import { VendingMachine } from '../../../types';
import { cl, cltext, getVmDetails } from '../../../utils/general';
import { checkOrder } from '../../../utils/purchase';
import {
    clearAllSavedStates,
    loadSavedVMState,
} from '../../../utils/stateStorage';
import { CheckQrProps } from './ScanQrCode';

export const checkQr = (params: CheckQrProps) => {
    /*
Response:
{
    "qr": "600444",
    "name": "VendingOperator",
    "vmNumber": "T02",
    "currency_code": "UAH",
    "language_code": "uk",
    "type_id": "1",
    "payment_provider_id": 1,
    "allowed": 1,
    "timeout": 90,
    "ct_type": 5,
    "max_credit_app": 20,
    "status_connected": 1
}
*/
    const {
        qr,
        onSuccess,
        onFailure,
        onNotSupported,
        onNotConnected,
        onFiscalService,
        onMainService,
        onsetVm,
        onFeedbacks,
    } = params;

    // staging only
    if (!isDev && isStage && !DEV_MACHINES.includes(qr)) {
        cl('Not supported VM');
        onNotSupported(qr);
        return;
    }

    const url = `${API.CHECK_QR}?qr=${qr}`;

    async function fetchQRData(url: string): Promise<void> {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const result: VendingMachine = await response.json();
            // cl('QR data parsed', result);
            const {
                qr,
                // type_id,
                // allowed,
                // status_connected,
                // ct_type,
                // language_code,
                QRservices,
                feedbacks,
                fisc_receipts,
            } = result;
            onsetVm(result);

            const { isAllowed, isFridge, isFridgeSmart, isCT5, isConnected } =
                getVmDetails(result);
            cl('kmg 122', getVmDetails(result));

            function checkAllowed(action: string, qr: string) {
                cltext(
                    `✅ Allowed: ${isAllowed} \nisFridge: ${isFridge}\naction: ${action} \nqr: ${qr}`
                );

                if (isAllowed || isFridge) {
                    checkOrder({
                        vmData: result,
                        qr,
                        notSupportedHandler: onNotSupported,
                        notConnectedHandler: onNotConnected,
                        successHandler: onSuccess,
                    });
                } else {
                    cltext(`🚫 Not allowed VM: ${qr}, action: ${action}`);
                    switch (action) {
                        case 'notSupported':
                            cltext('🚫 notSupported');
                            clearAllSavedStates();
                            onNotSupported(qr);
                            break;
                        case 'fiscalService':
                            cltext('🚫 fiscalService');
                            onFiscalService(qr);
                            break;
                        case 'mainService':
                            cltext('🚫 mainService');
                            onMainService(qr);
                            break;
                        case 'feedbacks':
                            cltext('🚫 feedbacks');
                            onFeedbacks(qr, result.vm_id);
                            break;
                    }
                }
            }

            function checkFiscal(
                isFeedbacksEnabled: boolean,
                hasFiscalReceipt: boolean
            ) {
                let action = 'notSupported';

                if (isFeedbacksEnabled) {
                    action = hasFiscalReceipt ? 'mainService' : 'feedbacks';
                } else {
                    if (hasFiscalReceipt) {
                        action = 'fiscalService';
                    }
                }

                checkAllowed(action, qr);
            }

            switch (QRservices) {
                case QR_SERVICES.NOTHING_IS_ALLOWED:
                    onNotSupported(qr);
                    break;
                case QR_SERVICES.SEPARATED_SERVICES:
                    checkAllowed('notSupported', qr);
                    break;
                default:
                    checkFiscal(feedbacks === 1, fisc_receipts === 1);
                    break;
            }
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            onFailure();
        }
    }

    const storedData = loadSavedVMState();
    cl('storedData 195', storedData);
    const { isSavedDataExpired } = storedData;
    const isSameQr = storedData?.qr === qr;

    if (storedData && !isSavedDataExpired && isSameQr) {
        cltext('using stored data', 'yellow');
        cl('using stored data', storedData);
        onsetVm(storedData);
    } else {
        cltext('no stored data', 'red;font-weight:bold;');
        fetchQRData(url);
    }
};
