import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { orderState, qrState } from '../../../state/recoilState';
import { BasicScreenProps } from '../../../types';
import { cl, screenLog } from '../../../utils/general';
import { trackPageView } from '../../../utils/tracking';
import { Button } from '../../common/Button';
import ContentBox from '../../common/ContentBox';
import FeedbackCustom from '../../common/FeedbackButtonLogic';
import PrimaryButton from '../../common/PrimaryButton';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';

interface RefundScreenProps extends BasicScreenProps {
    onRepeatPurchase: () => void;
}

const RefundScreen = (props: RefundScreenProps) => {
    const { t } = useTranslation();
    const qr = useRecoilValue(qrState);
    const order = useRecoilValue(orderState);

    const { showFeedback, onFeedback, onRepeatPurchase } = props;

    cl('RefundScreen', qr);
    useEffect(() => {
        trackPageView('refund', { orderId: order.id, ...order });
        screenLog('Refund');
    }, []);

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Failed Return')}</ScreenTitle>
            <ContentBox>
                <object
                    className='tick'
                    data='/cross.html'
                    style={{ overflow: 'hidden' }}
                />

                <FeedbackCustom
                    onFeedbackClick={onFeedback}
                    showFeedback={showFeedback}
                />
                <Button onClick={onRepeatPurchase}>
                    {t('Repeat_purchase')}
                </Button>
            </ContentBox>
        </ScreenWrapper>
    );
};

export default RefundScreen;
