import styled from 'styled-components';

const PromoText = styled.div`
  margin: 1vh 0 0 0;
  font-size: min(3.9vw, 25px);
  font-weight: medium;
  color: #f77f00;
`;

export default PromoText;
