import styled from 'styled-components';

const PriceBox = styled.div`
  margin: 1vh 0 1rem 0;
  font-size: min(9.4vw, 61px);
  font-weight: bold;
  color: #1b3f92;
  // position: relative;
  text-align: center;
`;

export default PriceBox;
