import { nanoid } from 'nanoid';
import {
    API,
    AVAILABLE_CT5_VM_TYPES,
    CT_TYPES,
    DEVICE_STATUS,
    MACHINE_TYPES,
    ORDER_STATUS,
    STORAGE_KEYS,
    TAB_START_TIMESTAMP_STORAGE_KEY,
} from '../constants';
import {
    Order,
    VendingMachine,
    VersionStructure,
    telemetryEventCheck,
} from '../types';

// eslint-disable-next-line
const isDevMode =
    import.meta.env.DEV ||
    window.location.hostname.startsWith('hunter-dev') ||
    localStorage.getItem(STORAGE_KEYS.DEBUG_IS_ALLOWED) === 'allowed';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const cl = isDevMode ? console.log : () => {};

export const cltext = (text: string, color = 'red', forced = false) => {
    if (!isDevMode) return;
    console.log(`%c${text}`, `color: ${color}; margin: 5px 10px 0;`);
};

isDevMode && cltext('📡 Spy mode, HUH', 'gold');

export const screenLog = (screenName: string) => {
    cltext(`📺 [${screenName}]`, 'green');
};

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function myDelayFunc(str = '', delay = 2000) {
    cl(`Start delay ${str}`);
    await sleep(delay);
    cl(`End delay ${str}`);
}

export const fetchData = async (
    url: string,
    onSuccess: any,
    onFailure: any
) => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw Error(`Response: ${response.statusText} URL: ${url}`);
        }
        const result = await response.json();
        onSuccess(result);
    } catch (error) {
        onFailure(error);
    }
};

export interface VmInfo {
    qr?: string;
    isCT10: boolean;
    isCT5: boolean;
    isCT1: boolean;
    isConnected: boolean;
    isAllowed: boolean;
    isFridge: boolean;
    isFridgeSmart: boolean;
    language_code?: string;
    isFeedbackEnabled?: boolean;
    hasFiscalReceipt?: boolean;
}
export interface VmOrderInfo extends VmInfo {
    isPaid: boolean;
    isProductSelected: boolean;
    isRefunded: boolean;
    isVmOnline: boolean;
}

export const defineVMOrderState = (vm: VendingMachine, order: Order) => {
    const { status_connected, type_id, ct_type, allowed } = vm;
    const {
        device_status,
        status,
        // fiscal_req,
        // fiscal_success_ID,
        // fiscal_failed_ID,
        // receipt_key,
    } = order;

    const isCT10 =
        vm.ct_type === CT_TYPES.CT10 ||
        (vm.ct_type === CT_TYPES.NULL && vm.type_id === MACHINE_TYPES.FRIDGE); // is it enough?

    const isCT5 =
        AVAILABLE_CT5_VM_TYPES.includes(type_id) && ct_type === CT_TYPES.CT5;
    const isCT5Connected =
        isCT5 &&
        status === ORDER_STATUS.PREPAID &&
        device_status === DEVICE_STATUS.CONNECTED;
    const isCT5Paid = isCT5 && status === ORDER_STATUS.PREPAID;
    const isCT5ProductSelected =
        isCT5 &&
        status === ORDER_STATUS.PREPAID &&
        device_status === DEVICE_STATUS.PRODUCT_SELECTED;
    const isCT5Refund = isCT5 && status === ORDER_STATUS.REFUNDED;

    // General statuses
    if (allowed !== 1) {
        return 'NOT_ALLOWED';
    }

    if (status === ORDER_STATUS.PAYMENT_FAIL) {
        return 'PAYMENT_FAIL';
    }

    // CT5 statuses
    if (isCT5 && status_connected === 0) {
        return 'NOT_CONNECTED';
    }
    if (isCT5Paid) {
        return 'PREPAID';
    }
    if (isCT5Connected) {
        return 'CONNECTED';
    }
    if (isCT5ProductSelected) {
        return 'PRODUCT_SELECTED';
    }

    return 'WAIT';
};

export const getVmDetails = (vm: VendingMachine) => {
    const result: VmInfo = {
        qr: vm.qr,
        isCT10: false,
        isCT5: false,
        isCT1: false,
        isConnected: vm.status_connected === 1,
        isAllowed: vm.allowed === 1,
        isFridge: vm.type_id === MACHINE_TYPES.FRIDGE,
        isFridgeSmart: vm.type_id === MACHINE_TYPES.FRIDGE_SMART,
        language_code: vm?.language_code ?? 'uk', // TODO validate default value
        isFeedbackEnabled: vm.feedbacks === 1,
        hasFiscalReceipt: vm.fisc_receipts === 1,
    };

    result.isCT10 =
        vm.ct_type === CT_TYPES.CT10 ||
        (vm.ct_type === CT_TYPES.NULL && vm.type_id === MACHINE_TYPES.FRIDGE);

    result.isCT5 =
        vm.ct_type === CT_TYPES.CT5 &&
        AVAILABLE_CT5_VM_TYPES.includes(vm.type_id as string); // CT5 case - only VMs of type 1..9 and are online

    return result;
};

export const getVmOrderState = (vm: VendingMachine, order: Order) => {
    const result: VmOrderInfo = {
        ...getVmDetails(vm),

        isPaid: false,
        isProductSelected: false,
        isRefunded: false,
        isVmOnline: false,
    };

    result.isVmOnline = order.device_status === DEVICE_STATUS.CONNECTED;

    return result;
};

export const getOrderInfoById = (
    orderId: string,
    onSuccess: (order: Order) => void,
    onFailure: () => void
) => {
    cl('kmg - getOrderInfo utils', orderId);

    try {
        if (!orderId || orderId === '' || orderId === null) {
            cl('kmg - 148 - no orderId');
            throw new Error('Order ID is not set - getOrderInfoById');
        }

        const orderInfoUrl = isDevMode
            ? `/dev/order_completed.json?id=${orderId}`
            : `${API.ORDER}?id=${orderId}`;

        fetchData(
            orderInfoUrl,
            (orderInfo: Order) => {
                cl('kmg - 171 - orderInfo', orderInfo);
                onSuccess(orderInfo);
            },
            () => {
                cl('Error loading order info');
                onFailure();
            }
        );
    } catch (e: any) {
        cl('Error:', e.message);
        onFailure();
    }
};

export const updateFeedbacks = (id: number, body: any): void => {
    fetch(`/api/post_feedbacks_data?id=${id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    }).catch((error) => {
        console.log('update error', error);
    });
};

export async function createTelemetryEvent(
    id: number,
    est: number | null,
    setResult: any
) {
    try {
        const response = await fetch(
            `/api/create_telemetry_event?id=${id}&est=${est}`
        );
        if (response.status == 200) {
            const result: telemetryEventCheck = await response.json();
            setResult(result);
            console.log('Event is created');
        } else {
            console.warn('Something goes wrong');
            throw new Error(response.statusText);
        }
    } catch (error) {
        console.error('Request failed:', error);
    }
}

export const updateTelemetryEvent = (
    id: number,
    est: number | null,
    event: any
) => {
    fetch(`/api/update_telemetry_event?id=${id}&est=${est}&event=${event}`)
        .then((response) => {
            if (response.status === 200) {
                console.log('Event is updated');
            }
        })
        .catch((error) => {
            console.log('update error', error);
        });
};

export const initUniqueTabData = (): void => {
    const tabId = getCurrentTabId();
    const tabTimestamp = Date.now();

    localStorage.setItem(STORAGE_KEYS.TAB_ID, tabId);
    localStorage.setItem(
        TAB_START_TIMESTAMP_STORAGE_KEY,
        tabTimestamp.toString()
    );
    sessionStorage.setItem(STORAGE_KEYS.TAB_ID, tabId);
    sessionStorage.setItem(
        TAB_START_TIMESTAMP_STORAGE_KEY,
        tabTimestamp.toString()
    );
    sessionStorage.removeItem(STORAGE_KEYS.TAB_EXPIRED);

    // dev
    const existingTabIds = localStorage.getItem(STORAGE_KEYS.TAB_IDS);
    localStorage.setItem(STORAGE_KEYS.TAB_IDS, existingTabIds ?? ' ' + tabId);
    const date = new Date(tabTimestamp);
    const time = date.toLocaleTimeString('uk-UA');
    localStorage.setItem(`${tabId}`, time);
};

export const getUrlParams = (key = ''): any => {
    const urlParams = new URLSearchParams(window.location.search);

    if (key) {
        return urlParams.get(key);
    }

    return {
        qr: urlParams.get('qr') ?? '',
        nfc: urlParams.get('nfc') ?? '',
        service: urlParams.get('service') ?? '',
    };
};

// clear all session storage data
export const clearSessionStorage = (): void => {
    sessionStorage.clear();
};

export const getCurrentTimeStr = () => {
    const date = new Date();
    return date.toLocaleTimeString('uk-UA');
};

export const getCurrentTabId = (): string => {
    return sessionStorage.getItem(STORAGE_KEYS.TAB_ID) ?? nanoid(10);
};

export const getCurrentTabStartTimestamp = (): number => {
    return (
        Number(sessionStorage.getItem(STORAGE_KEYS.TAB_START_TIMESTAMP)) ??
        Date.now()
    );
};

export const isUniqueTab = (tabId: string): boolean => {
    return tabId === localStorage.getItem(STORAGE_KEYS.TAB_ID);
};

export const isTabExpired = (tabId: string): boolean => {
    // TODO: check if tab is expired
    return false; // tabId === sessionStorage.getItem(TAB_EXPIRED_STORAGE_KEY);
};

export const clearStoredTabData = (tabId: string): void => {
    localStorage.removeItem(tabId);
    localStorage.removeItem(STORAGE_KEYS.TAB_ID + ' ' + tabId);
    localStorage.removeItem(STORAGE_KEYS.TAB_ID + ' ' + tabId + ' lifetime');

    // TODO remove all tab ids from localStorage
    localStorage.removeItem(`${STORAGE_KEYS.TAB_ID} ${tabId}`);
};

export const checkCurrentTabUniqueness = (): {
    tabId: string;
    tabTimestamp: number;
    tabExpired: boolean;
    existingLStimestamp: string | null;
    isUniqueTab: boolean;
} => {
    const tabId = getCurrentTabId();
    const tabTimestamp = getCurrentTabStartTimestamp();
    const existingLStimestamp = localStorage.getItem(
        TAB_START_TIMESTAMP_STORAGE_KEY
    );
    const tabExpiredMarker = localStorage.getItem(STORAGE_KEYS.TAB_EXPIRED);
    let tabExpired = false;

    if (isUniqueTab(tabId)) {
        tabExpired = false;
    }

    return {
        tabId,
        tabTimestamp,
        existingLStimestamp,
        isUniqueTab: isUniqueTab(tabId),
        tabExpired,
    };
};

export async function getPrivacyVersion(
    showCookieBanner: any,
    setCookieData: any
) {
    const response = await fetch(API.LEGAL_UPDATE);
    if (!response.ok) {
        showCookieBanner(false);
        throw new Error('legal version check request failed');
    }
    const result: VersionStructure = await response.json();
    setCookieData(result);
    const cookieBanner = document.cookie.replace(
        /(?:(?:^|.*;\s*)cookieBanner\s*=\s*([^;]*).*$)|^.*$/,
        '$1'
    );
    let shouldShowBanner = true;
    if (cookieBanner) {
        const cookieBannerObj = JSON.parse(cookieBanner);
        const { PP, ToU } = result;
        shouldShowBanner =
            PP != cookieBannerObj.PP || ToU != cookieBannerObj.ToU;
    }

    showCookieBanner(shouldShowBanner);
}
