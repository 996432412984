import styled from 'styled-components';
import {Button} from './Button';

const PrimaryButton = styled(Button)`
  background: linear-gradient(270deg, #5c81ed 5.76%, #b984fa 92.6%);
  color: #ffffff;
  // text-transform: uppercase;
`;

export default PrimaryButton;
