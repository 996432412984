import { Checkbox, FormControlLabel } from '@mui/material';
import { CheckedCheckbox, EmptyCheckbox } from './CheckboxItems/CheckboxSvg';

export function CustomCheckbox (props: any) {
  const { id, label, onChange } = props;
  return (
    <FormControlLabel
              control={
                <Checkbox
                  id={id}
                  icon={<EmptyCheckbox />}
                  checkedIcon={<CheckedCheckbox />}
                  onChange={onChange}
                />
              }
              label={label}
              sx={{ color: '#1B3F92' }}
            />
  );
}
