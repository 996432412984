import styled from 'styled-components';

const Button = styled.button`
  background: #dae3f7;
  border-radius: 18px;
  box-shadow: 0 15px 15px rgb(0 0 0 / 30%);
  color: #5c81ed;
  font-size: min(4.2vw, 27px);
  height: min(13vw, 60px);
  letter-spacing: 0.5px;
  line-height: 15px;
  margin-bottom: 10px;
  margin-top: 8px;
  max-height: 85px;
  max-width: 400px;
  min-height: 40px;
  padding: 12px 0px;
  text-align: center;
  text-transform: lowercase;
  width: 65vw;
  border: none;
`;
const SmallButton = styled(Button)`
    max-height: 60px;
    max-width: 32vw;
    padding: 5px 0px;
    width: 32vw;
    height: min(16vw, 60px);
`;
const SmallPrimaryButton = styled(SmallButton)`
  background: linear-gradient(270deg, #5c81ed 5.76%, #b984fa 92.6%);
  color: #ffffff;
  // text-transform: uppercase;
`;
export {Button, SmallButton, SmallPrimaryButton};
