import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { LIMITS } from '../../../screensSettings';
import { orderState, vmDataState } from '../../../state/recoilState';
import { BasicScreenProps } from '../../../types';
import { cl, cltext, screenLog } from '../../../utils/general';
import { setOrderAmount } from '../../../utils/payments';
import { trackEvent, trackPageView } from '../../../utils/tracking';
import { Button } from '../../common/Button';
import ButtonBox from '../../common/ButtonBox';
import ContentBox from '../../common/ContentBox';
import ErrorText from '../../common/ErrorText';
import FeedbackAndReceipt from '../../common/FeedbackAndReceipt';
import Input from '../../common/Input';
import InputWrapper from '../../common/InputWrapper';
import PaymentButtons from '../../common/PaymentButtons/PaymentButtons';
import PriceBox from '../../common/PriceBox';
import PrimaryButton from '../../common/PrimaryButton';
import PromoText from '../../common/PromoText';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';

type PaymentScreenProps = BasicScreenProps;

const PaymentCT10Screen = (props: PaymentScreenProps) => {
    const { showFeedback, onFeedback, onCancel, showReceipt, onGetReceipt } =
        props;
    const { t } = useTranslation();

    const { vm } = useRecoilValue(vmDataState);
    const [order, setOrder] = useRecoilState(orderState);

    const [amount, setAmount] = useState<number | ''>('');
    const [serverValidatedAmount, setServerValidatedAmount] =
        useState<number>();
    const [showPromoText] = useState<boolean>(
        Boolean(vm?.campaign?.message && vm?.campaign?.message.length > 0)
    );
    const [showPaymentButton, setShowPaymentButton] = useState<boolean>(false);
    const [amountSend, setAmountSend] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    useEffect(() => {
        trackPageView('payment CT10', { orderId: order.id, ...order });
        screenLog('Payment CT10');
    }, []);

    // Logic
    // 1. Check available payment methods
    //    1.1. Apple Pay is available?
    //    1.2. Google Pay is available if not Apple Pay
    // 2. show relevant buttons

    const onInputChangeHandler = (event: any) => {
        const input = event.target.value.replace(',', '.');
        const maxLimit = Math.min(vm.max_credit_app, LIMITS.PAYMENT_CT10.MAX);
        let enteredAmount = Number(input);

        if (enteredAmount <= 0) {
            enteredAmount = 0;
        }

        // check for VM limits
        if (enteredAmount > maxLimit) {
            enteredAmount = maxLimit;

            setError(
                `${t('The entered amount is too big.')} ${t('Max amount is')} ${
                    vm.max_credit_app
                } ${t(`Currency.${vm?.currency_code}`)}`
            );
            setShowPaymentButton(false);
        } else {
            setError('');
        }

        cl('enteredAmount 73', enteredAmount);
        setShowPaymentButton(enteredAmount > 0 && enteredAmount <= maxLimit);
        cltext(`showPaymentButton 75 ${showPaymentButton}`);

        const discount = vm?.campaign?.discount_percentage
            ? 1 - vm?.campaign?.discount_percentage / 100
            : 1;
        setAmount(enteredAmount);
        const realAmount = enteredAmount * 100 * discount;
        cl('realAmount 81', realAmount);
        trackEvent('Input value', {
            input_value: enteredAmount,
            amount_to_DB: realAmount
        });
        setOrderAmount(order.id as string, realAmount, setAmountSend);
        setOrder({
            ...order,
            amount: realAmount,
        });
    };

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Enter amount')}</ScreenTitle>
            <ContentBox>
                <div className='content'>
                    <InputWrapper>
                        <Input
                            type='number'
                            inputMode='decimal'
                            // min={LIMITS.PAYMENT_CT10.MIN}
                            max={LIMITS.PAYMENT_CT10.MAX}
                            onChange={onInputChangeHandler}
                            value={amount && amount > 0 ? amount : ''}
                            placeholder={t<string>('Amount in currency', {
                                currency: t(`Currency.${vm?.currency_code}`),
                            })}
                        />
                        <ErrorText className='error'>{error}</ErrorText>
                    </InputWrapper>
                </div>
                <div className='pay_text'>{t('Order payment')}</div>
                {showPromoText && (
                    <PromoText>{vm?.campaign?.message}</PromoText>
                )}
                <div>
                    <PriceBox>
                        <span id='price'>
                            {(order?.amount / 100).toFixed(2)}
                        </span>{' '}
                        {t(`Currency.${vm?.currency_code}`)}
                    </PriceBox>

                    {/* {Boolean(vm.campaign?.discount_percentage) && (
            <DiscountMessage>
              {t('Your discount is ')}
              {vm.campaign?.discount_percentage}%
            </DiscountMessage>
          )} */}
                </div>
                {showPaymentButton && amountSend ? (
                    <ButtonBox>
                        <PaymentButtons />
                        <Button onClick={onCancel}>{t('Cancel')}</Button>
                    </ButtonBox>
                ) : (
                    <FeedbackAndReceipt
                        showReceipt={showReceipt}
                        showFeedback={showFeedback}
                        onCancelClick={onCancel}
                        onReceiptClick={onGetReceipt}
                        onFeedbackClick={onFeedback}
                    />
                )}

                {/* {showPaymentButton && <PaymentButtons />}
                <Button onClick={onCancel}>{t('Cancel')}</Button> */}
            </ContentBox>
        </ScreenWrapper>
    );
};

export default PaymentCT10Screen;
