import styled from 'styled-components';

const ScreenTitle = styled.h1`
  font-size: min(6.5vw, 42px);
  font-weight: 400;
  color: #1b3f92;
  padding-bottom: 1rem;
  text-align: center;
`;
export default ScreenTitle;
